import React from 'react'
import venderfastsection from "./vender2.jpeg"
import vendorsecondsection from "./VMI.jpg"
import vendorthirdsection from "./vender3.jpeg"



export default function Vender() {
  return (
    <div className='pt-16'>
      {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
      {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
    {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white font-bold text-center'>Vendor Managed Inventory</h1>
                
             </div>
    </div> 
    </div><br /> */}

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={venderfastsection} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Join MINDCOM for the Success of VMI</h1>
            <p className="text-black text- pr-10">Managing your supply chain is essential in the face of increased competition and financial restrictions. MINDCOM will professionally handle and store your industrial supplies, class C component fasteners, and essential parts as your vendor managed inventory (VMI) partner. You can rely on us to offer excellent outcomes, thanks to our process-driven methodology and cost-saving solutions.</p>
            </div>
      </div>
      {/* <div className='p-2 bg-red-600'>
        <h1 className='text-white text-2xl font-semibold text-center p-20'>MINDCOM goes beyond the transaction to become a part of your team, keeping your business running smoothly and <br /> making sure you achieve your desired goals.

</h1>
        
      </div> */}
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">With VMI Services, you can increase supply chain efficiency</h1>
            <p className="text-black text-lg pr-10">Maximize the efficiency of your supply chain with MINDCOM’s Vendor Managed Inventory (VMI) solutions. Our process commences with an in-person consultation to evaluate your current inventory management system. We then collaborate with you to create a personalized VMI program that boosts your productivity. Our skilled team employs the 5S methodology and usage data analysis to determine the ideal product mix, stocking levels, and restock frequency. To assist you in making informed inventory purchasing decisions and achieving superior supply chain efficiency, we offer continual support through comprehensive usage reports. With our VMI services, you can optimize your inventory management, minimize waste, and increase your profitability.</p>
            {/* <h1 className='text-black text-xl font-bold'>Optimize your supply chain with MINDCOM’s VMI Services.</h1> */}
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={vendorsecondsection} alt="" />
            </div>
      </div>  
      
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="pr-10 h-full py-12" src={vendorthirdsection} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Advantages of Vendor Managed Inventory</h1>
            <p className="text-black text-lg pr-10">MINDCOM’s VMI Services can bring substantial advantages to your operations and finances, such as:</p>
            <p className="text-black text-lg pr-10"><b>  Decreased on-hand inventory </b>–  Our VMI approach establishes clear minimum and maximum levels, supporting just-in-time principles and resulting in reduced inventory levels.</p>

            <p className="text-black text-lg pr-10"><b>  Better pricing </b> – We coordinate orders based on production schedules, leading to lower procurement costs. Furthermore, our services liberate your personnel to concentrate on higher-value activities.</p>
            <p className="text-black text-lg pr-10"><b> Improved accuracy </b> –MINDCOM ensures that bins are correctly filled, resulting in improved efficiency.</p>
            <p className="text-black text-lg pr-10"><b> Increased fill rate </b> –Our systematic reviews and consistent communication minimize stockouts and enhance fill rates.</p>
            <p className="text-black text-lg pr-10"><b> Reduced shipping costs </b>  –  Our expertise allows us to optimize orders and achieve economic order quantities, resulting in lower shipping costs.</p>
            </div>
      </div>

      {/* <div>
      
 <h1 className='text-red-500 font-bold text-5xl text-center'>VMI Case Study</h1>
 <p className='text-lg text-black text-center'>A foundry used drums for scrap material, but struggled to keep the right amount on hand, causing major issues with production. On top of that, they were paying a premium price. MINDCOM took over management of the customer’s drums, including sourcing and biweekly delivery, saving the customer $30,466 in inventory costs.</p>
      </div> */}
      
      </div>

    </div>
  )
}
