import React from 'react'

import AssertManagement from "./Assert.jpg"
export default function Stores() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
    <div>
            <img  className="px-16  pt-10 pb-10 h-full" src={AssertManagement} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          
          <h1  className="text-red-500 text-5xl font-bold">Asset Management Services</h1>
          <p className="text-black text-lg pr-10">Our asset management services are designed to help customers establish a culture of information-driven asset maintenance, leading to cost savings, reduced downtime, and increased efficiency. We offer plant floor asset and work management services that enable business transformation from "Run to Failure" to "Condition Based Monitoring." <br />
          MINDCOM is one of the leading companies in the world that can bring the 3Ps for a Single Source Asset & Work Management solution – Platform, Process, and Personnel. We integrate our traditional commodity management expertise with plant and asset management services to offer comprehensive solutions to our customers. <br />
          Our goal is to improve Return on Assets (ROA) by increasing Overall Equipment Effectiveness (OEE), Mean Time Between Failure (MTBF), Mean Time Between Repair (MTBR), reliability, and reducing scheduled vs. actual variance, maintenance costs, equipment lifecycle costs, emergency work repetitive failures, and maintenance per production unit.
          We are at the forefront of the mobility revolution, reducing unnecessary data entry, travel, and paperwork while improving first-time fix rates. Our core solutions are cloud-based, eliminating the need for customers to maintain internal IT infrastructure and allowing us to scale as the business grows.

</p> 

</div>
  </div>
  </div>
  <div className='mx-auto p-5 bg-red-600'>
      <h1 className='text-white text-xl font-bold text-center '>MINDCOM’s asset management services create a culture of efficient maintenance that saves time and money while reducing downtime and improving overall efficiency </h1>
      
    </div>
  </div>
  )
}
