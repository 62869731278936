import React from 'react'

import ChemicalManagement from "./Chemical.jpg"
export default function Chemical() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
    <div>
            <img  className="px-16  pt-10 pb-10 h-full w-full max-h-96" src={ChemicalManagement} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          
          <h1  className="text-red-500 text-5xl font-bold">Chemical Management Services</h1>
          <p className="text-black text-lg pr-10">At MINDCOM, we understand that every project has unique requirements when it comes to chemical management. That's why we offer a customized Chemical Management Program that's tailored to meet the needs of any scope of work. As a vendor-neutral product provider, we offer a wide range of services, including on-site chemical management, on-site chemical engineers, safety, environmental, and administrative professionals. <br />
Our Total Cost Management method is designed to optimize product, process, and tool applications, resulting in reduced overall costs, increased profitability, and environmental solutions through usage reduction, recycling, and waste management.

</p>
          </div>
        
        
        
    
    </div>
    {/* <div className='mx-auto grid md:grid-cols-3'>
        <div></div>
        <div>
        <h1 className='text-red-600 text-2xl font-bold'>Our key features include :</h1>
        <p className='text-lg'>
           <b className='text-4xl pr-2 font-bold text-red-600'>.</b>    <br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b>  <br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b>  <br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b>  <br />
       <b className='text-4xl pr-2 font-bold text-red-600'>.</b>  <br />
       <b className='text-4xl pr-2 font-bold text-red-600'>.</b>  Lubricants<br />
       <b className='text-4xl pr-2 font-bold text-red-600'>.</b>  Coolants<br />
            </p>
            </div>
            <div></div>
    </div> */}

   <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold text-red-600'>Our Expertise</h1>
          <p className='text-lg  text-center'>   Chemical Management <br /> Waste Water Management <br /> Inter-Linked Commodity Managementbr <br /> Filtration Management <br /> Metal Working Fluids <br /> Lubricants <br /> Coolants
 </p>        
  </div>

    </div>
    
 
  </div>
  )
}
