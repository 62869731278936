import React from "react";
import supplychainconsultant from "./supply-chain-consultants.webp";

import aboutboxfast from "./aboutboxfast.jpg";
import aboutboxsecond from "./aboutboxsecond.jpg";
import aboutboxthird from "./aboutboxthird.jpg";
import SingleBlog from "../Blog/SingleBlog/SingleBlog";
// import Skills from "../pages/Skills/Skills.js";
import Skills from "../Skills/Skills.js";

export default function About() {
  return (
    // <div>About
    <div className="pt-20">
      //
      <div className="grid md:grid-cols-3 px-6 md:px-24">
        <div className="pt-10 col-span-2">
          <h1 className="text-red-500 text-5xl font-bold">About MINDCOM </h1>
          <p className="text-black text-lg pr-10">
            MINDCOM is one of the leading global MRO supply chain services
            company. We strive to lead in implementation and management of
            solutions focused on supply chain optimization for metalworking and
            industrial supply commodities. The objective of these solutions is
            to reduce customers “Total Cost” while increasing customer
            satisfaction. Our team of procurement specialists, analysts,
            engineers and information technology personnel has a passion for
            continuous improvement. <br />
            MINDCOM combines resources to offer better commodity management
            solutions and achieve reduced procurement costs. Our breadth and
            depth of product lines from multiple suppliers and value-added
            services will streamline vendors, resulting in dramatic cost
            savings. We manage the costs associated with supply chain management
            including inventory costs, paperwork, invoicing, and quoting. Our
            team & Process develops a thorough understanding of your goals,
            products, services, and an implementation plan that takes advantage
            of the vast supply network.
          </p>
        </div>
        <div className="col-span-1">
          <img
            className=" px-16  pt-12 pb-10 h-full max-h-96"
            src={supplychainconsultant}
            alt=""
          />
        </div>
      </div>
      <div className="px-8 md:px-64">
        <h1 className="text-red-600 text-4xl font-bold text-center">
          Providing Global MRO Solutions
        </h1>
        <p className="text-lg">
          MINDCOM collaborates with some of the biggest businesses worldwide
          across a range of sectors, including the aviation, automotive,
          hospitality, chemical, manufacturing, healthcare, higher education
          campuses, and more. <br />
          Manufacturing, assembly, and process sector clients can rely on us for
          outsourced procurement, storeroom management, and value-added
          solutions for MRO and indirect supplies.
          <br />
          Every one of our initiatives is supported by cutting-edge technology
          created especially for the expanding MRO digital supply chain. Our
          technology enhances the performance of the supply chain, the
          effectiveness of maintenance, and business analytics to increase
          equipment reliability.
          <br />
          The solution from MINDCOM combines data about your equipment
          performance, supply chain, and maintenance into a single source from
          which you can design a strategy and make fact-based decisions that
          will improve your operations. In order to achieve customer success
          globally, we have the infrastructure and partner connections
          necessary. <br />
        </p>
      </div>
      <div className="blog" id="blog">
        <div className="blog--body">
          <div className="blog--bodyContainer p-5">
            {/* {blogData.slice(0, 3).reverse().map(blog => ( */}
            <SingleBlog
              // theme={theme}
              title={"Career Opportunities"}
              desc={
                "People are our number one asset. We are committed to creating a diverse, inclusive, and safe workplace."
              }
              image={aboutboxfast}
              // url={blog.url}
              key={1}
              id={1}
            />
            <SingleBlog
              // theme={theme}
              title={"Global Reach"}
              desc={"MINDCOM has operations in USA and INDIA."}
              image={aboutboxsecond}
              // url={blog.url}
              key={2}
              id={2}
            />
            <SingleBlog
              // theme={theme}
              title={"Leadership Team"}
              desc={
                "Our commitment to delivering client value starts at the top."
              }
              image={aboutboxthird}
              // url={blog.url}
              key={3}
              id={3}
            />
          </div>
        </div>
      </div>
      <div>
        <Skills />
      </div>
      <div className="h-auto bg-red-700">
        <div className="p-10 pl-2 md:pl-52">
          <h1 className="text-white text-4xl font-bold px-4 md:px-40">
            Our Vision
          </h1>
          <p className="text-lg text-white px-4 md:px-40">
            By creating cutting-edge solutions and value-added services, keeping
            up our investment in people, process, and technology, and providing
            the best procurement/MRO solutions to our customers resulting in
            cost savings, we aim to achieve this goal.
          </p>
        </div>
      </div>
    </div>
  );
}
