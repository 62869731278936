import React from 'react'
import section1 from "./Traing No. 1.jpeg"
import section2 from "./Traning No. 2.jpeg"
import section3 from "./trainingsection3.jpg"
export default function Training() {
  return (
    <div className='pt-16'>
      {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
      {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
    {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white font-bold text-center'>Vendor Managed Inventory</h1>
                
             </div>
    </div> 
    </div><br /> */}

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Empowering Teams with Safe Work Practices</h1>
            <p className="text-black text-lg pr-10">At MINDCOM , our goal is to equip and educate teams to prevent workplace hazards and ensure their safe return home. We offer a range of safety trainings, covering OSHA mandatory, general, and tailored topics, designed to be easily understandable and taught by knowledgeable and engaging instructors. Whether your team is involved in automotive production, handling dangerous materials, or scaling utility structures, they need to be informed and equipped with the right tools and techniques to stay safe. </p>
            </div>
      </div>
      {/* <section>

      
    </section> */}
    
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Need Help with Required Trainings?</h1>
            <p className="text-black text-lg pr-10">Confused about the necessary trainings for your specific business operations or unsure about recent OSHA regulations? Reach out to us for a FREE Safety Consultation.</p>
            {/* <h1 className='text-black text-xl font-bold'>Optimize your supply chain with MINDCOM’s VMI Services.</h1> */}
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
            </div>
      </div>  <br />

      {/* <div>
        <h1 className='text-red-500 font-bold text-5xl text-center'>Safety Training Case Study</h1>
        <p className='text-lg text-black text-center'>We help companies improve worker safety and regulatory compliance.
To learn more, read one of our success stories.</p>
 <img  className="py-10  mx-auto block" src={section3} alt="" />
 <h1 className='text-red-500 font-bold text-5xl text-center'>Training</h1>
 <p className='text-lg text-black text-center'>The safety team at a major manufacturer was struggling to provide in-depth safety training to employees while focusing on the company’s EHS issues. After collaborating with MINDCOM’s OSHA licensed trainer, we custom-tailored a training plan to meet their needs and decrease recordable injuries.</p>
      </div> */}
      
      </div>

    </div>
  )
}
