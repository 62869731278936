import React from 'react'

import InventoryManagement from "./Inventory.png"
export default function Inventory() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
          <div>
            <img  className="px-16  pt-10 pb-10 h-full" src={InventoryManagement} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          
          <h1  className="text-red-500 text-5xl font-bold">Inventory Management</h1>
          <p className="text-black text-lg pr-10">The first step in building an effective inventory management system is to understand your business's demand-flow. At MINDCOM, we specialize in helping you determine when and where you'll need components, and designing a system that seamlessly integrates with your company's existing infrastructure to automate every step of the process. <br />
          With our demand-flow technologies, you can rest assured that your component bins will be monitored and replenished in real-time to keep up with production. Our electronic infrastructure and EDI systems eliminate delays and reduce paper waste by immediately sensing and filling orders via scanner, fax, and other systems. We even plan safety stock to ensure you never run low during high-volume demand times.</p> 
     
          </div>
    </div>
  
    
    <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold text-red-600'>Our Expertise</h1>
          <p className='text-lg  text-center'>A globally available internet-based system <br /> Internet accessible usage and audit reports <br /> Inventory and user control through smart cabinets <br /> Secure storage <br /> A full audit trail <br /> Management of return and reuse capabilities <br /> Supplier access to inventory usage <br /> Replenishment plans that are automatically triggered
 </p>        
  </div>
 
    </div>
    
 
  </div>
  )
}
