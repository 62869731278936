import React from 'react'
// import Fade from 'react-reveal/Fade';

// import placeholder from '../../../assets/png/placeholder.png'
import './SingleBlog.css'

function SingleBlog({ theme, title, desc, date, image, url, id,tag }) {
    return (
        
        <div >

            <a className="singleBlog" key={id} href={url} target="_blank" rel="noreferrer" style={{backgroundColor: "white"}}>
                <div className="singleBlog--image" style={{backgroundColor: "blue"}}>
                    <img src={image ? image : " "} alt={title} />
                </div>
                {tag}
                <div className="singleBlog--body">
                    <h3 className='text-left text-red-600 font-semibold text-2xl'>{title}</h3>
                    <p className='text-left text-black text-lg' >{desc}</p>
                </div>
            </a>
        </div>
    
    )
}

export default SingleBlog
