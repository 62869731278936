import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "../common/Footer";
import { Header } from "../common/Header";
import { Home } from "../home/Home";
// import { About } from "./About"
import { Blog2 } from "./Blog2";
// import { Contact } from "./Contact"
import Contact2 from "./Contact2";
import { Portfolio } from "./Portfolio";
import { Servicessss } from "./Servicessss";
import { Testimonials } from "./Testimonials";
import Automobile from "./Automobile";
import Aviation from "./Aviation";
import It from "./It";
import Healthcare from "./Healthcare";
import Education from "./Education";
import Bfsi from "./Bfsi";
import Careers from "./Careers";
import Oracle from "./Enterprise/Oracle";
import Salesforce from "./Enterprise/Salesforce";
import Sap from "./Enterprise/Sap";
import Agile from "./Application/Agile";
import Devops from "./Application/Devops";
import Microservices from "./Application/Microservices";
import Qa from "./Application/Qa";
import Servicedesk from "./Lifecycle/Servicedesk";
import Content from "./Lifecycle/Content";
import Management from "./Lifecycle/Management";
import Mservises from "./Lifecycle/Mservises";
import Finance from "./Lifecycle/Finance";
import Rims from "./Lifecycle/Rims";
import Thirdparty from "./Lifecycle/Thirdparty";
import CloudServices from "./Cloud/CloudServices";
import PrivateCloud from "./Cloud/PrivateCloud";
import Engineering from "./Engineering/Engineering";
import Staffing from "./Staffing/Staffing";
import Hospitality from "./SolutionProduct/Hospitality";
import Scm from "./SolutionProduct/Scm";
import Mcms from "./SolutionProduct/Mcms";
import Affiliation from "./AboutUs/Affiliation";
import Business from "./AboutUs/Business";
import Leadership from "./AboutUs/Leadership";
import Locationcontact from "./AboutUs/Locationcontact";
import Locationoffices from "./AboutUs/Locationoffices";
import Partners from "./AboutUs/Partners";
import Whatwestandsfor from "./AboutUs/Whatwestandsfor";
import WhoWeAre from "./AboutUs/WhoWeAre";
import WorkforceManagement from "./magnite_blue_white/workforce_managementplatform";

// rsintegration
import About from "./rs/About";
// import Contact from "./rs/Contact"
// indurstrial
import Custom from "./rs/solution/indurstrial/Custom";
import Tool from "./rs/solution/indurstrial/Tool";
import Vender from "./rs/solution/indurstrial/Vender";
import Vending from "./rs/solution/indurstrial/Vending";

import CommodityManagement from "./rs/solution/indurstrial/CommodityManagement";
import ChemicalManagement from "./rs/solution/indurstrial/ChemicalManagement";
import InventoryManagement from "./rs/solution/indurstrial/InventoryManagement";
import StoresManagement from "./rs/solution/indurstrial/StoresManagement";
import AssertManagement from "./rs/solution/indurstrial/AssertManagement";
import VendingSolution from "./rs/solution/indurstrial/VendingSolution";

// integrated
import Maintenance from "./rs/solution/integrated/Maintenance";
import Procurement from "./rs/solution/integrated/Procurement";
import Reporting from "./rs/solution/integrated/Reporting";
import Storeroom from "./rs/solution/integrated/Storeroom";
// solution_plus
import Audits from "./rs/solution/solution_plus/Audits";
import Training from "./rs/solution/solution_plus/Training";

export const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path='/about' component={About} /> */}
          <Route exact path="/servicessss" component={Servicessss} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/blog2" component={Blog2} />
          {/* <Route exact path='/contact' component={Contact} /> */}
          <Route exact path="/contact2" component={Contact2} />
          <Route exact path="/automobile" component={Automobile} />
          <Route exact path="/aviation" component={Aviation} />
          <Route exact path="/it" component={It} />
          <Route exact path="/healthcare" component={Healthcare} />
          <Route exact path="/education" component={Education} />
          <Route exact path="/bfsi" component={Bfsi} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/oracle" component={Oracle} />
          <Route exact path="/salesforce" component={Salesforce} />
          <Route exact path="/sap" component={Sap} />
          <Route exact path="/agile" component={Agile} />
          <Route exact path="/devops" component={Devops} />
          <Route exact path="/microservices" component={Microservices} />
          <Route exact path="/qa" component={Qa} />
          <Route exact path="/servicedesk" component={Servicedesk} />
          <Route exact path="/content" component={Content} />
          <Route exact path="/management" component={Management} />
          <Route exact path="/mservises" component={Mservises} />
          <Route exact path="/finance" component={Finance} />
          <Route exact path="/rims" component={Rims} />
          <Route exact path="/thirdparty" component={Thirdparty} />
          <Route exact path="/cloudservices" component={CloudServices} />
          <Route exact path="/privatecloud" component={PrivateCloud} />
          <Route exact path="/engineering" component={Engineering} />
          <Route exact path="/staffing" component={Staffing} />
          <Route exact path="/hospitality" component={Hospitality} />
          <Route exact path="/scm" component={Scm} />
          <Route exact path="/mcms" component={Mcms} />
          <Route exact path="/affiliation" component={Affiliation} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/leadership" component={Leadership} />
          <Route exact path="/locationcontact" component={Locationcontact} />
          <Route exact path="/locationoffices" component={Locationoffices} />
          <Route exact path="/partners" component={Partners} />
          <Route exact path="/whatwestandsfor" component={Whatwestandsfor} />
          <Route exact path="/whoweare" component={WhoWeAre} />
          <Route exact path="/about" component={About} />
          <Route exact path="/custom" component={Custom} />
          <Route exact path="/tool" component={Tool} />
          <Route exact path="/vender" component={Vender} />
          <Route exact path="/vending" component={Vending} />
          <Route
            exact
            path="/CommodityManagement"
            component={CommodityManagement}
          />
          <Route
            exact
            path="/ChemicalManagement"
            component={ChemicalManagement}
          />
          <Route
            exact
            path="/InventoryManagement"
            component={InventoryManagement}
          />
          <Route exact path="/StoresManagement" component={StoresManagement} />
          <Route exact path="/AssertManagement" component={AssertManagement} />
          <Route exact path="/VendingSolution" component={VendingSolution} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route exact path="/procurement" component={Procurement} />
          <Route exact path="/reporting" component={Reporting} />
          <Route exact path="/storeroom" component={Storeroom} />
          <Route exact path="/audits" component={Audits} />
          <Route exact path="/training" component={Training} />
          <Route
            exact
            path="/workforce_management"
            component={WorkforceManagement}
          />
        </Switch>

        {/* <Contact/> */}
        {/* <Footer /> */}
      </Router>
    </>
  );
};
