import React from 'react'
import section1 from "./storeroomsection11.jpeg"
import section2 from "./storeroomsection22.jpg"
import section3 from "./storeroomsection33.jpg"
import sectionfast from "./storeroomsectionfastt.jpg"

export default function Storeroom() {
  return (
    <div className='pt-16'>
     

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={sectionfast} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Scalable Storeroom Solutions</h1>
            <p className="text-black text-lg pr-10">MINDCOM’s Storeroom Management solutions are scalable and can effectively address unmanaged storerooms that cause financial losses. These losses include unstable cash flow, increased carrying costs, poor inventory control, and lost revenue. Our solutions are designed to streamline your MRO supply needs, eliminate costly downtime and expenses that come with mismanaged storerooms, and ultimately reduce your overall cost of ownership.</p>
            </div>
      </div>
      <div className='p-10 bg-red-600'>
        
        <h1 className='text-white text-xl font-semibold text-center pt-4'>MINDCOM is an agile when it comes to optimizing your storeroom, helping you better manage your MRO supply, save money and stabilize pricing so you can focus on your value stream</h1>
        
      </div>
      <div className="grid md:grid-cols-3">
     








            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">MINDCOM Storeroom Management</h1>
            <p className="text-black text-lg pr-10"> At MINDCOM, we understand that efficient storeroom management is critical for the success of your business. We start by performing a Storeroom Reset, and then work closely with you to review your current inventory processes and identify areas for improvement. Together, we create solutions that ensure you always have the right parts on hand, at the right time and in the right quantity.
              <br />
              Our Storeroom Management program is data-driven, and we constantly review data such as Min/Max levels, disbursement history, and lead times. This allows us to take advantage of quantity breaks, optimize the physical layout of the storeroom, and address any trends that need to be discussed with suppliers. We believe that taking early action with data helps prevent crises in the future.
              <br />Our services include: <br />
              {/* <b className='text-3xl font-bold text-black'>.</b>Our services include <br /> */}
              <b className='text-4xl font-bold text-red-600'>. </b> Physical inventory assessments<br />
              <b className='text-4xl font-bold text-red-600'>. </b> Data scrubbing <br />
              <b className='text-4xl font-bold text-red-600'>. </b> Reset and reorganization <br />
              <b className='text-4xl font-bold text-red-600'>. </b> Custom e-catalog creation <br />
              </p>
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
            </div>
      </div>  
      
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Enterprise Asset Management</h1>
            <p className="text-black text-lg pr-10">MINDCOM's Enterprise Asset Management provides a comprehensive solution to optimize your inventory and maintenance processes. Our services include:</p>
            <p className="text-black text-lg pr-10"><b> Enhanced inventory accuracy: </b> We utilize robust cycle counting and inventory management tools to ensure that your machines always have the necessary spare parts to maintain their overall effectiveness.</p>
            <p className="text-black text-lg pr-10"><b>Improved spare part reliability: </b>  We understand that the reliability of spare parts is crucial. That's why we handle routine motor shaft rotations and provide dust-free, anti-static storage to ensure that your spares are functional when needed.</p>

            <p className="text-black text-lg pr-10"><b> Increased enterprise inventory visibility:</b> Our state-of-the-art software platform or integration with your ERP/CMMS software gives you visibility of spare parts across your entire enterprise. We help you develop a program to share high-dollar spare parts among all your business units, reducing the capital required to run your operations.</p>

            <p className="text-black text-lg pr-10"><b> Optimized Computerized Maintenance Management System (CMMS): </b>Whether you're looking to improve your work-order management process or seeking better results from your existing CMMS system, MINDCOM can drive improvements to your workflows and processes with industry-leading data sharing techniques and our CMMS tools.</p>
             </div>
      </div>
      <div className="grid md:grid-cols-3">
           
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Benefits of MINDCOM’s Storeroom Management</h1>
            <p className="text-black text-lg pr-10">Elevate your company's performance and simplify operations with MINDCOM's Storeroom Management solution, providing these key benefits:</p>
            <p className="text-black text-lg pr-10"><b> Optimize plant operations: </b>Our Storeroom Management solution helps you operate more efficiently and profitably, delivering immediate results.</p>

            <p className="text-black text-lg pr-10"><b> Streamline inventory needs:</b> We eliminate part redundancy, consolidate vendors, and reduce inventory costs, aligning your inventory to your specific needs.</p>

            
            <p className="text-black text-lg pr-10"><b> Control costs: </b> We help you effectively manage your storeroom, reducing rogue spending and unnecessary expenses, allowing you to focus on what matters most.</p>

            <p className="text-black text-lg pr-10"><b> Boost production and equipment uptime:</b> A clean and organized storeroom improves production capacity, minimizes the time spent searching for supplies, and ensures that your equipment has the parts required for both routine and emergency maintenance, keeping your operations running smoothly.</p>
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section3} alt="" />
            </div>
      </div>

      <div className='pt-10'>
       
 <h1 className='text-red-500 font-bold text-5xl text-center'>Inventory Reduction</h1>
 <p className='text-lg text-black text-center'>MINDCOM partnered with an energy client faced with skyrocketing inventory costs and production line shutdowns. Through a collaborative process, we assumed responsibility for their procurement, stocking, shipping, and line management of two high-use C-class components. The result was a significant reduction in their customer-owned inventory, allowing them to operate more efficiently and cost-effectively while keeping their lines running smoothly.</p>
      </div>
      
      </div>

    </div>
  )
}
