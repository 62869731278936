import React from "react";
import { Link } from "react-router-dom";
import { About } from "../pages/About";
import { Contact } from "../pages/Contact";
import { Testimonials } from "../pages/Testimonials";
import Skills from "../pages/Skills/Skills.js";
import { Hero } from "./Hero";
import Blog from "../pages/Blog/Blog.js";
import SingleBlog from "../pages/Blog/SingleBlog/SingleBlog";
import careerrhomebox from "./careerhomebox.jpg";
import aboutusbox from "./aboutusbox.jpg";
import sustainability from "./sustainability.jpg";
import Card from "../pages/card/Card";
import Gridcard from "./gridcard";
// import mrosolution from "../../Images/mro-solution-400x277"
import mrosolution from "../../Images/mro-solution-400x277.webp";
import storeroommanager from "../../Images/storeroom-manager.jpeg";
import card10fast from "./card10fast.jpeg";
import card10second from "./card10second.jpg";
import card10third from "./card10third.jpg";

import smallcard1 from "./northamerica.png";
import smallcard2 from "./Europe.png";
import FaqDropdown from "../pages/rs/solution/FAQdropdown";

export const Home = () => {
  return (
    <>
      <Hero />

      <div className="px-8 md:px-56">
        <div className="grid md:grid-cols-2">
          <div className="pt-10">
            <h1 className="text-red-500 text-5xl font-bold">
              Reliability Matters
            </h1>
            <p className="text-black text-lg pr-10">
              MINDCOM provides procurement, inventory management, CPU, and
              storeroom solutions to meet the challenges of manufacturing,
              assembly, and processing operations. MINDCOM is a leading company
              in Engineering Services and Commodity Management Services for
              Tooling and Abrasives Services, Chemical Management and Industrial
              Supplies.Our mission is to drive value for customers across
              various industries, including aviation, automotive, hospitality,
              chemical, manufacturing, healthcare, and higher education
              campuses. The benefits of working with MINDCOM include cost
              savings, innovation, and continuous improvement by implementing
              supply chain best practices, consolidation and standardization
              through continuous improvement, and process innovation.{" "}
            </p>
          </div>
          <div>
            <img
              className=" px-16  pt-12 pb-10 h-full max-h-96"
              src={mrosolution}
              alt=""
            />
          </div>
        </div>
      </div>

      <Gridcard />

      {/* <div className=' bg-red-900 h-auto pt-0 '>
        
        <div className="px-8 md:px-64 grid gap-4 md:grid-cols-3 ">
          <div className="py-5">
            <div className="smallcard h-52 ">
              <h1 className="text-white text-2xl font-bold text-left">Global Footprint</h1>
              <p className="text-white text-lg text-left">MINDCOM is the first MRO supply chain services company with true global reach. Our operational model is designed to provide consistent services and savings to multi-site clients across the globe.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="smallcard  font-bold bg-red-600 flex h-52 "style={{backgroundImage:`url(${smallcard1})`}}>
              <h1 className="text-white text-2xl text-left self-end pl-5 pb-4">INDIA</h1>
            </div>
          </div>
          <div className="py-5">
            <div className="smallcard font-bold bg-red-400 flex h-52"style={{backgroundImage:`url(${smallcard2})`}}>
              <h1 className="text-white text-2xl text-left self-end pl-5 pb-4">UNITED STATES</h1>
              
            </div>
          </div>
        </div>
      
    </div> */}

      <div className="px-8 md:px-64">
        <div className="grid md:grid-cols-2">
          <div className="pt-10">
            <h1 className="text-red-500 text-5xl font-bold">
              Frequently Asked Questions
            </h1>
            <br />
            <FaqDropdown
              question="What is integrated supply?"
              answer="Integrated supply is the end-to-end process of managing the MRO supply chain (spare parts) through consolidated sourcing practices, storeroom operations, inventory management, data governance, and continuous improvement. The objective is to leverage spend, reduce transactions, and cut inventory and associated costs while eliminating risk around critical spares."
            />
            <FaqDropdown
              question="What are the benefits of working with an integrator?"
              answer="Outsourcing your MRO allows you to focus on core competencies and strategic priorities. Among the benefits of an integrated supply program are:
                
                . Cost/purchase avoidance, innovation, and continuous improvement by implementing supply chain technology and best practices
                . Total cost savings impacting material, logistics, process time and labor
                . Reduced capital expense, demand, and spend from consistent and disciplined inventory management and optimization
                Increased plant reliability
                . Vendor consolidation, P2P reduction, and lower overhead by reducing the number of transactions and associated costs
                . Real-time reporting of transactions, inventory, and more
                "
            />
            <FaqDropdown
              question="How does an integrator differ from a distributor"
              answer="An integrator’s core expertise is in the management of the entire MRO supply chain, including storeroom operations, inventory optimization, data governance, and continuous improvement. The primary focus is improving the supply chain and plant reliability. A distributor is typically focused on specific brand/line cards and on selling more products. The goal of any integrated supply program is to stop relying on item price and, instead, measure value on the total cost of ownership."
            />
          </div>
          <div>
            <img
              className="px-16  pt-12 pb-10 h-full"
              src={storeroommanager}
              alt=""
            />
          </div>
        </div>
      </div>
      <br />
      <Skills />
      <br />
      <div className="h-auto bg-red-600">
        <div className="grid md:grid-cols-3 p-5 md:p-20">
          <div className="col-span-2">
            <h1 className="text-xl md:text-2xl text-white font-bold pl-0 md:pl-60">
              Interested in our services? Contact us for more information.
            </h1>
          </div>
          <div className=" pl-5 md:pl-32">
            <Link to="/contact2">
              <button class="bg-white rounded-md text-black text-xl font-bold">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
