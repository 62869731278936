import React from 'react'
import customsection1 from "./Customsection11.jpg"
import customsection2 from "./customsection22.jpg"
import customsectionfast from "./customsectionfast.jpg"
export default function Custom() {
  return (
    <div className='pt-16'>
      

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={customsectionfast} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Simplify your safety and MRO supply ordering process with MINDCOM.</h1>
            <p className="text-black text-lg pr-10">Are you tired of uncontrolled spending and a complicated ordering process for supplies in your organization? Look no further than MINDCOM. Our custom eCommerce solution includes a personalized catalog that puts you back in control. Our eCatalog features your approved safety and industrial supplies at your dedicated pricing, making the purchasing process fast, simple, and completely tailored to your needs.</p>
            </div>
      </div>
      <div className='p-2 bg-red-600'>
        <h1 className='text-white text-xl font-bold text-center p-20'>Regain control over your business with MINDCOM's top-notch e-commerce solutions.</h1>
        
      </div>
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Tailored e-Catalog Solution to Meet Your Needs</h1>
            <p className="text-black text-lg pr-10">Experience a hassle-free ordering process with MINDCOM's customized eCatalog solution. Our team of experts will design an online catalog that features all your frequently purchased safety and industrial products. With your personalized customer portal, you can easily and conveniently place orders using your computer or mobile device. Browse through various brands, categories, or part numbers, access detailed product information and images, and enjoy exclusive pricing that reflects your customer discount.</p>
            <h1 className='text-black text-xl font-bold'>Effortless Procurement through PunchOut Integration</h1>
            <p className="text-black text-lg pr-10">With MINDCOM's eCommerce solution, you can benefit from PunchOut Integration that seamlessly connects your company's ERP system or eProcurement platform. This integration simplifies the procurement process, allowing you to effortlessly initiate and process orders from start to finish.</p>
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={customsection1} alt="" />
            </div>
      </div>  
      
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={customsection2} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Advantages of a Customized eCatalog</h1>
            <p className="text-black text-lg pr-10">MINDCOM's personalized eCatalog delivers a host of advantages, such as:</p>
            
            <p className="text-black text-lg pr-10"><b> 24x7 Ordering </b> –Place orders via your dedicated customer portal at any hour of the day.</p>
            <p className="text-black text-lg pr-10"><b> Tailored Shopping Lists </b>  –Create customized lists of frequently ordered products for faster and more efficient ordering.</p>
            <p className="text-black text-lg pr-10"><b> Order Tracking </b>–Keep tabs on your previous orders, including their shipment details and status, to enhance purchasing dependability.</p>
            <p className="text-black text-lg pr-10"><b> Access Control </b>–Restrict access to your custom portal to authorized personnel only, for greater accountability.</p>
            <p className="text-black text-lg pr-10"><b> Automated Approval Workflow </b>– Streamline the purchase order process by automatically routing orders to the relevant staff for review.</p>
            </div>
      </div>

      
      </div>

    </div>
  )
}
