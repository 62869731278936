import React from 'react'
import Healthcarebg from './healthcare.jpg'
export default function Healthcare() {
   const imageUrl = Healthcarebg
  return (
  <div className='pt-16 px-8 md:px-24'>
    
    <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16 pt-12 pb-10 h-full" src={Healthcarebg} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Turn Disruptions Into Advantages. Become A Healthcare Leader</h1>
            <p className="text-black text-lg pr-10"> Medical responsibility of the employee, directly or indirectly, remains a key part of human resource management and is a company burden, as it involves diversion of a lot of time & energy of company manpower in a niche, multispecialty area that could be utilized otherwise. There are hassles of database maintenance and other hurdles related to 24 hours decision making responsibilities. To over come this scenario and to assist the corporate, we proudly presents Total Employee Healthcare solutions. Our aims to simplify the corporate health management, helping the companies to have a simple and hassle free employee medical solution.</p>
      </div>
      </div>

  <div >
      <div className="container2 bg-white">
      
     
      
      
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Process driven operations</h1>
              <p> Reduce Operational Cost Improve safety through best SOPs Reduce administrative headaches by being simple& single point contact for all medical needs. Ability to replicate efficient model at smaller places </p>
          </div>
      </div>
      
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Record Maintenance and Digitalization</h1>
              <p>Each employee database will be created and maintained by us for medical purposes Initial medical will be arranged as per the company standards at no extra cost for the company. All medical related issues like appointment , follow up and maintenance of medical records will be performed by us. </p>
      </div>
      </div>
    

  </div>
  </div>


  
          
 

          <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold pt-10 text-red-600'>Our Expertise</h1>
          <p className='text-lg'>Turn your website and intranets into a high-performing, interactive destination with our feature-rich CMS platform. Deliver contextualized content, seamless interaction, and ultimate experience for users</p>
              
          <p className='text-lg'>Get clinicians back to work fast with industry-leading 24/7/365 customer service from support analysts trained in business information systems.</p>
              
          <p className='text-lg'>As an empathetic IT support provider, we always go the extra mile to help you better serve patients as they navigate the new landscape of patient portals, telehealth, and remote patient monitoring.</p>
              
             

        </div>
          </div>


  )
}
