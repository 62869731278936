import React from "react";
import { useState } from "react";
import section1 from "./contactsection11.jpeg";
export default function Contact2() {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  return (
    <>
      <div className="px-8 md:px-56 pt-20">
        <div className="grid md:grid-cols-2">
          <div className="pt-10">
            <h1 className="text-red-500 text-5xl font-bold pt-20">
              Contact Us
            </h1>
            <p className="text-black text-xl pr-10">
              Learn more about our procurement services. Please contact us for more information.
            </p>
          </div>
          <div>
            <img
              className="px-16  pt-10 pb-10 float-right"
              src={section1}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container3 px-40">
        <div className="row grid md:grid-cols-3">
          <div className="col-md-4">
            <div className="card4 card3-1">
              <div className="flex flex-col justify-center items-center h-52 bg-red-800 ">
                <i
                  className="icon text-center far fa-map fa-5x"
                  aria-hidden="true"
                ></i>
                <h3 className="text-center text-3xl text-white font-semibold">
                  Office <br /> Location
                </h3>
              </div>
              <div>
                <p className="text-center text-lg">
                  MINDCOM, Chairman Layout, <br /> 100ft Road, Banaswadi, <br />{" "}
                  BANGALORE - 560043
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card4 card3-1 ">
              <div className="flex flex-col justify-center items-center h-52 bg-red-500">
                <i
                  className="icon text-center fas fa-map-marked fa-5x"
                  aria-hidden="true"
                ></i>
                <h3 className="text-center text-3xl text-white font-semibold py-3">
                  MINDCOM <br /> USA INC
                </h3>
              </div>
              <div>
                <p className="text-center text-lg">
                  1985 W HENDERSON RD,
                  <br /> COLUMBUS OH 43220
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card4 card3-1">
              <div className="flex flex-col justify-center items-center h-52 bg-red-800">
                <i
                  className="icon text-center far fa-envelope-open fa-5x"
                  aria-hidden="true"
                ></i>
                <h3 className="text-center text-3xl text-white font-semibold">
                  Email <br /> Information
                </h3>
              </div>
              <div>
                <p className="text-center text-lg">
                  info@mindcomgroup.com <br />
                  Send us your query anytime!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-auto bg-red-600">
        <div className="grid md:grid-cols-3 p-20">
          <div className="col-span-2 pl-2 md:pl-52">
            <h1 className="text-3xl text-white font-bold">
              Career Opportunity{" "}
            </h1>
            <p className="text-xl text-white ">
              As a recognized leader MINDCOM is always looking for qualified
              indivisual to join our team please visit our career page for
              current openings and additional information.
            </p>
          </div>
          <div></div>
        </div>
      </div>
      {/* <div className='pt-20 bgofall h-auto' style={{backgroundImage:`url(${imageUrl})`}}> */}
      {/* <div className='grid lg:grid-cols-2 p-5 md:p-16'>
        <div>
        <h1 className='text-4xl text-white pl-10 font-semibold font-serif'>CONTACT US</h1>
                 <p className='text-white text-xl font-style: italic pl-10'>Have A Support Expert Contact You</p>


          <h1 className='font-bold text-white'>
           
          </h1>
          <h1 className='text-2xl md:text-4xl font-bold text-white'>
           
          </h1>
          <p className='md:text-lg text-white'></p>
        </div>
        <div className='bg-white z-0 pb-14 w-5/6'>
          <Form/> 
        </div>
      </div> */}
      {/* </div> */}

      {/* 
      
    </form> */}
      <div className="h-screen bg-gray-100 pt-14">
        <form className="max-w-xl mx-auto shadow-lg p-10 bg-white">
          <h1 className="text-red-600 text-2xl font-bold pb-10">
            How can we help you?
          </h1>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block font-medium mb-2 text-xl text-black"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 p-2 text-black rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="block font-medium mb-2 text-xl text-black"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full border border-gray-300 p-2 text-black rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block font-medium mb-2 text-xl text-black"
            >
              Phone No
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border border-gray-300 p-2 text-black rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block font-medium mb-2 text-xl text-black"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full border border-gray-300 p-2 text-black rounded-md"
              rows="5"
              required
            ></textarea>
          </div>
          <button
            // type="submit"
            className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-800"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
