import React from 'react'

import StoresManagement from "./StoresManagement.png"
export default function Stores() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
    <div>
            <img  className="px-16  pt-10 pb-10" src={StoresManagement} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          
          <h1  className="text-red-500 text-5xl font-bold">Store Set-up and Management</h1>
          <p className="text-black text-lg pr-10"> This service is structured to set-up the general stores for all the items used by a facility and to provide on-going resources to manage the stores.</p> 

</div>
        
        
        
    
    </div>
    <div className='mx-auto grid md:grid-cols-3'>
        <div></div>
        <div>
        <h1 className='text-red-600 text-2xl font-bold'>Key Feature</h1>
        <p>
           <b className='text-4xl pr-2 font-bold text-red-600'>.</b> On-Site Service<br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b> 24 Hour Availability <br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b> Inventory Management <br />
		<b className='text-4xl pr-2 font-bold text-red-600'>.</b>Supplier Management<br />
       <b className='text-4xl pr-2 font-bold text-red-600'>.</b>Expediting<br />
        








            </p>
            </div>
            <div></div>
    </div>
    </div>
    
 
  </div>
  )
}
