import React from 'react'
import section1 from "./Procurement No. 1.jpeg"
import section2 from "./Procurement No. 2.jpg"
import section3 from "./Procurement No. 3.jpg"

export default function Procurement() {
  return (
    <div className='pt-16'>
      {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
      {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
    {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white font-bold text-center'>Procurement</h1>
                
             </div>
    </div> 
    </div><br /> */}
 

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Leave the sourcing to the professionals.</h1>
            <p className="text-black text-lg pr-10">By leaving your indirect material sourcing to the professionals at MINDCOM you can increase profitability and avoid downtime. Our procurement services assist you in better managing your vendor interactions and MRO supply requirements, resulting in increased production cost savings and efficiency. We'll take care of the procurement process for you thanks to our extensive supplier network and experience with small-volume purchases</p>
            </div>
      </div>
      <div className='p-5 bg-red-600'>
        <h1 className='text-white text-xl font-bold text-center p-5'>MINDCOM can simplify your sourcing to generate time and cost savings.

</h1>
        
      </div>
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">MRO Management</h1>
            <p className="text-black text-lg pr-10">Streamline your MRO management with MINDCOM. We work closely with you to develop scalable solutions that consolidate suppliers, re-engineer processes, and secure the best possible price for your purchases. Our solutions include:
              <br />
              Streamlining tail-spend and routing purchases through MINDCOM <br />
              Leveraging your total spend with our world-class supply chain partners <br />
              Simplifying invoicing <br />
              Funneling purchases through preferred supplier agreements to the most cost-effective channel</p>
            <h1 className='text-black text-xl font-bold'>Our team can work with any ERP or CMMS system to ensure a seamless transition.</h1>
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
            </div>
      </div>  
      
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section3} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Procurement Benefits</h1>
            <p className="text-black text-lg pr-10">Through strategic and tactical purchasing, MINDCOM can help you:</p>
            <p className="text-black text-lg pr-10"><b>Decrease labor costs</b>and save time with consolidated purchasing through MINDCOM</p>
            <p className="text-black text-lg pr-10"><b> Streamline sourcing</b>through long-standing supplier relationships and easy routing of purchases through MINDCOM</p>
            <p className="text-black text-lg pr-10"><b> Gain product and sourcing expertise</b> by working with MINDCOM to identify the best supplier partners for your MRO needs</p>
            <p className="text-black text-lg pr-10"><b> Drive cost savings through elimination of rogue spend,  </b>established pricing parameters, fewer spot buys, reduced invoice processing, and leveraging total supplier spend for the best pricing.</p>
             </div>
      </div>

      
      
      </div>

    </div>
  )
}
