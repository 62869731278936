import { useState } from 'react';

function FaqDropdown({ question, answer }) { 
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white shadow-lg rounded-lg mb-4">
      <button
        className="w-full text-left px-4 py-2 bg-white text-gray-700 font-semibold focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <svg
          className={`w-4 h-4 inline-block ml-2 transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        //   fill="currentColor"
        //   viewBox="0 0 20 20"
        //   xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="px-4 pb-2 text-gray-600">{answer}</div>
      )}
    </div>
  );
}

export default FaqDropdown