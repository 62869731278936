import React from 'react'
import vendingcard1 from "./vc1.jpg"
import vendingcard2 from "./vc2.jpg"
import vendingcard3 from "./vc3.jpg"
import vendingsection2 from "./vs2.jpg"
import vendingfastsection from "./vending1stsection.jpeg"
export default function Vending() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
    <div>
            <img  className="px-16  pt-10 pb-10" src={vendingfastsection} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Experience optimum efficiency with our vending services.</h1>
          <p className="text-black text-lg pr-10">MINDCOM industrial vending machines offer the ultimate solution for supply shortages. With our efficient storage, dispensing, and monitoring system, you can access your frequently used MRO and safety supplies anytime you need them. Say goodbye to stock-out woes and count on MINDCOM to keep you well-stocked at all times.</p>
          </div>
        
    </div>
    <div className='h-60 bg-red-600'>
      <h1 className='text-white text-xl font-bold text-center p-20'>Our team of vending specialists at MINDCOM is dedicated to ensuring that your machines are always fully stocked and ready to serve you. With our assistance, you can bid farewell to inefficiencies and make quick, well-informed production decisions.</h1>
      
    </div>
<br />
    <div>
      <h1 className='text-3xl text-red-500 font-bold text-center'>MINDCOM  Vending Services</h1>
      <p className='text-center text-lg'>MINDCOM Vending Services provide industrial vending machines that guarantee swift access to crucial tools, components, safety PPE, and other MRO supplies, all while ensuring the appropriate level of security. We offer the most extensive range of technologies and configurations, providing you with the utmost flexibility, safety, and efficiency on the shop floor. Our industrial and safety vending machine options include:</p>
      <br />
      <div className='grid md:grid-cols-3 gap-10'>
        <div>
        <img  className="px-16  pt-10 pb-10" src={vendingcard1} alt="" />
          <h1 className='text-2xl font-bold text-black text-center'>Helix Coil Vending Machines</h1>
          <p className='text-justify text-black text-lg'>Our most popular vending machine style is the Helix Coil Vending Machine. It is designed to vend high-use consumable items, including safety PPE such as gloves and safety goggles, tools and screwdrivers, spare parts and batteries, material processing and cutting discs, building materials, and paintbrushes. With the Helix Coil, you can conveniently access all the essential items you need on the go.</p>
        </div>
        <div>
        <img  className="px-16  pt-10 pb-10" src={vendingcard2} alt="" />
        <h1 className='text-2xl font-bold text-black text-center'>Drawer Vending Machines</h1>
          <p className='text-justify text-black text-lg'>Our Drawer Vending Machine is the perfect solution for vending small MRO consumables, safety items, shop supplies, or welding equipment. It is particularly suitable for workplaces that require a vast selection of tooling, such as carbide and high-speed steel cutting tools, welding consumables, and tool and die parts for stamping operations. With this machine, you can easily access all the necessary items to keep your work environment running smoothly.</p>
        </div>
        <div>
        <img  className="px-16  pt-10 pb-10" src={vendingcard3} alt="" />
        <h1 className='text-2xl font-bold text-black text-center'>Locker Vending Machines</h1>
          <p className='text-justify text-black text-lg'>With its sturdy build and ample lighting, the Locker Vending Machine offers a practical solution for checking in and checking out bulky items, such as power tools, large MRO supplies, and high-cost, returnable items that may not fit in other vending solutions. This machine is designed to provide you with the flexibility and convenience you need to manage your inventory effectively.</p>
        </div>

      </div>
    </div>

    <div className="grid md:grid-cols-3">
          
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Industrial Vending Benefits</h1>
          <p className="text-black text-lg pr-10"> MINDCOM industrial vending solutions help you optimize productivity and minimize downtime by keeping tools and supplies within easy reach of your employees. With usage monitoring and reporting features, you can effectively manage costs and operations while enhancing accountability through advanced tracking technology.</p>
          <p className="dot text-lg">Inventory optimization through reduced usage</p>
          <p className="dot text-lg">Enhanced accountability with advanced usage tracking technology</p>
          <p className="dot text-lg">Web-based solutions for ease of access</p>
          <p className="dot text-lg">Advanced reporting with usage tracking by device, user, or job</p>
          <p className="dot text-lg">Improved job costing through better tracking of indirect materials</p>
          <p className="dot text-lg">Speedy replenishment through customizable alerts to specific personnel and MINDCOM</p>
        
          </div>
          <div>
            <img  className="px-16  pt-10 pb-10" src={vendingsection2} alt="" />
          </div>
    </div>  

<br /> 
    </div>

  </div>
  )
}
