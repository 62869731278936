import React from 'react'

import CommodityManagement from "./Commodity.jpg"
export default function Commodity() {
  return (
    <div className='pt-16'>
  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
    <div>
            <img  className="px-16  pt-10 pb-10 max-h-80 w-full" src={CommodityManagement} alt="" />
          </div> 
          <div className="pt-10 md:col-span-2">
          
          <h1  className="text-red-500 text-5xl font-bold">Commodity Management</h1>
          <p className="text-black text-lg pr-10">At MINDCOM, we know that effective commodity management is crucial for businesses to succeed. That's why we've pooled our resources to provide superior solutions that can help you achieve significant procurement cost reductions. Our diverse product lines sourced from multiple suppliers, coupled with our value-added services, simplify your vendors, resulting in substantial savings. <br />
          We address all the costs associated with managing your supply chain, including paperwork, invoicing, and quoting, so you can focus on your core business. Our team takes the time to understand your goals, products, and services, developing a customized implementation plan that takes full advantage of our vast supply network.</p>
          </div>
    </div>
  

<div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold text-red-600'>Our Expertise</h1>
          <p className='text-lg  text-center'>  Access to one of the best manufacturers, ensuring quality and reliability <br />
     Reduced vendors, streamlining procurement <br />
     Technical and engineering support to help you make informed decisions <br />
     A cutting-edge web-based supply chain management application to simplify your processes <br />
     Material requirement planning (MRP) to optimize your inventory <br />
     Improved inventory management to reduce waste and save money <br />
     Product standardization to simplify your operation and ensure consistency <br />
     Offering you the best products at competitive prices</p>        
  </div>

  </div>
    <div className='mx-auto p-5 bg-red-600'>
      <h1 className='text-white text-xl font-bold text-center'>At MINDCOM, we're committed to delivering the best commodity management solutions for your business.</h1>
      
    </div>
  </div>
  )
}
