import React from 'react'
import Bfsibg from './banking.jpg'
export default function Bfsi() {
    
    return (
    <div className='pt-16 px-8 md:px-24'>
    
    <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-12 pb-10 h-full" src={Bfsibg} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Get the Right Financial Services Solutions</h1>
            <p className="text-black text-lg pr-10">The new normal has posed a set of unique challenges for the financial sector, which has already been grappling with the conventional concerns about improving efficiency, reducing costs, and meeting regulatory and compliance requirements. The emergence of competition from non-conventional participants such as bigtechs, fintechs and platform players, along with the arrival of neo-business models, has caused unprecedented disruption. <br />
      As a result, customer and stakeholder engagement and speed to market have emerged as decisive factors. This terraformation, however, demands a change in the mindset of financial sector companies if they want to show resilience and follow the upward growth curve in this ever-evolving digital world. <br />
      Transform with our digital solutions for financial services. MINDCOM leverages its expertise in emerging and digital technologies such as cloud, data and analytics, AI & ML, and blockchain to enable you to become a nimble, data-driven, and smart organization that drives deeper customer engagement across various channels.</p>
      
            </div>
      </div>
        {/* <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold pt-10 text-red-600'>Our Capabilities</h1>
       <p className='text-lg'>Simplify, streamline, and make GRC processes seamless by aligning risk and governance management with your strategic business goals, proactively identifying risks, and ensuring well-informed decision-making.</p>
           </div> */}


    
    <div className="container2 bg-white pt-5 ">
    <div className="card">
        <div className="content2">
            <h1>Customer Data Standardization</h1>
            <p className='text-lg'>Ensure the quality of your customers’ contact details to connect and serve them better by standardizing email addresses, contact addresses, and phone numbers.</p>
           </div>
    </div>
    <div className="card">
        <div className="content2">
            <h1>Customer Identity Resolution</h1>
            <p className='text-lg'>Reduce errors, ensure faster processing, and minimize manual intervention by accurately and effectively comparing business critical documents with MINDCOM’s AI-driven solution.</p>
           </div>
    </div>
    <div className="card">
        
        <div className="content2">
            <h1>Customer Communication Integration</h1>
            <p className='text-lg'>Drive deeper customer engagement with MINDCOM’s plug-n-play solution that helps you integrate any core system platforms with SmartCOMM, thereby enabling personalized customer communications.</p>
        </div>
    </div>
    <div className="card">
       
        <div className="content2">
            <h1 className='text-3xl'>Trade Order Management System</h1>
            <p className='text-lg'>Manage orders, streamline global trading requirements, and improve operational efficiency by automating processes to achieve straight-through processing.</p>
         </div>
    </div>
   
</div>
    <div className="container2 bg-white pt-5 ">
    <div className="card">
       
       <div className="content2">
           <h1 className='text-3xl'>Regulatory Reporting Solution</h1>
           <p className='text-lg'>Automate and streamline processes for generating accurate and timely regulatory reports with our scalable and flexible solutions, allowing you to meet future demands in the digital world.</p>
       </div>
   </div>
   <div className="card">
      
       <div className="content2">
           <h1 className='text-3xl'>Customer Identity Resolution</h1>
           <p className='text-lg'>Resolve non-obvious identities / relationships to discover people within a household, organization, or a locale, retrieve credit worthiness and demographic details, validate KYC, and perform watch-list filtering.</p>
          </div>
   </div>
</div>




          <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold pt-10 text-red-600'>Our Services</h1>
          <p className='text-lg'>We have been helping global businesses scale up digital transformation across their enterprises by exploring the new possibilities that banking technology solutions offer. At the heart of our Digital Services is innovation, powered by our Digital Transformation Campus. It’s where our best minds work in a state-of-the-art environment and apply advanced capabilities , leveraging emerging technologies. <br />
            Our vast pool of qualified and skilled resources, industry-leading technology partnerships, and deep domain experience- be it in insurance or banking, help our clients embrace modern technologies to improve customer experience and create more opportunities for innovation and strategic solutions. From industry-specific services such as insurance consulting services and property and casualty insurance solutions to horizontal banking & financial services, explore our range of digital services for transformation and growth in this new world.</p>
          
            <p className='text-lg'>Step up your leadership in the P&C business with MINDCOM. We bring the most modern solution delivery approaches and an array of pre-built accelerators that get you the maximum out of your investments.
              </p>
              <p className='text-lg'>Deliver smarter customer conversations, powered by our partnership with the technology platform. From advisory, implementation, and support services, leverage our services to win your customers’ confidence.
              </p>
              <p className='text-lg'>Get the best of both worlds: industry-leading P&C software and an implementation partner committed to providing outstanding delivery outcomes, implementations and proven delivery accelerators.
              </p>
        </div>



          
        

    </div>
  )
}
