import React from 'react'
import Itbg from './it2.jpg'
export default function It() {
    return (

    <div className='pt-16 px-8 md:px-24'>
    
    <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16 pt-12 pb-10 h-full" src={Itbg} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">CUSTOM APPLICATION DEVELOPMENT</h1>
            <p className="text-black text-lg pr-10"> MINDCOM undertakes custom application development either as a turn-key solution or as part or larger client development team. Our custom application development services provide the it backbone for many projects, right from project initiation to development,implementation and support.
          </p>
      <p className='text-lg text-justify text-gray-600'>MINDCOM can efficiently custom-build to customer's specifications and certainly deliver on its promise. MINDCOM streamlines and updates client's application management processes for maximum efficiency and control. MINDCOM can help in building mission-critical and complex applications that address your unique business requirements and bring individualized process innovation to market. We use our pre- designed applications and custom solutions to continually transform your offerings and improve performance.</p>
      <p className='text-lg text-justify text-gray-600'>MINDCOM pioneers in providing enterprises the advantage of custom software application development, outsourcing and web support. We excel in custom application design, related development and integration services for companies seeking to enhance custom business applications. We use our thorough knowledge of software tools, technology components, application integration techniques and multi-platform environments to create optimally integrated systems that support our clients' business and technology requirements.</p></div>
      </div>
    



    <div >
        <div className="container2 bg-white">
        <div className="card">
          
            <div className="content2">
                <h1>Content/Document Management System</h1>
                <p>Customized and cost-effective ECM services and solutions using IBM FileNet, MarkLogic, SharePoint, and Open Source software</p>
            </div>
        </div>
        <div className="card">
            
            <div className="content2">
                <h1>Workflow and Business Process</h1>
                <p>Solutions to help clients adapt to digital disruption with appropriate workflow and business process management capabilities</p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>Search Technologies</h1>
                <p> SEO techniques to help structure your content so that users can easily find the required information from your publications and gain insights </p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>Digitization Services</h1>
                <p>Repurposing of your content by organizing and converting it to digital formats of your preference quickly, accurately, securely, and cost-effectively </p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>Data Archiving and Preservation</h1>
                <p>Process design to help you decide the appropriate digital format based on how long you choose to store / retain the data, ensuring the trustworthiness of the chosen repository or archive  </p>
            </div>
        </div>
  
    </div>
    </div>
  
  


        <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold pt-10 text-red-600'>Our Expertise</h1>
          <p className='text-lg'>Helps connect, engage, interact, and collaborate better with stakeholders by offering the best-in-class, cross-channel user experience with compelling functionality
              </p>
          <p className='text-lg'>Accelerates the development of customized software solutions by applying our proven software product engineering services
              </p>
          <p className='text-lg'>Enables publishing of interactive digital content anytime, anywhere, over any network, to any device
              </p>
          <p className='text-lg'>Confers measurable business value by reducing TCO and increasing ROI
              </p>
          <p className='text-lg'>Seamlessly integrates all key IT assets, enables them to work in sync with CRM, ERP, payment gateway, workflow, and other critical applications to provide an engaging, seamless, and unified experience for internal and external stakeholders
              </p>
          <p className='text-lg'>Customized mobile applications to meet strategic mobile initiatives for reliable and effective sharing of digital content anywhere, anytime, and on any device
              </p>

        </div>
  
  
      {/* <div className="row py-10 bg-slate-200">
        <div className="col-md-4">
          <div className="card3 card3-1">
            <h3>Ionic Native</h3>
            <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card3 card3-2">
            <h3>UI Components</h3>
            <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
              of mobile UI components, ready to go.</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card3 card3-3">
            <h3>Theming</h3>
            <p>Learn how to easily customize and modify your app’s design to fit your
              brand across all mobile platform styles.</p>
          </div>
        </div>
      </div> */}
    </div>
  )
}
