import React from 'react'
import Firstbg from "./RIMS1.jpg"
export default function Rims() {
  const imageUrl = Firstbg
  return (
 
  <div className='pt-16'>
    <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}>
    <div className="bgofall2 flex h-60 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white pl-10 font-bold pt-10 font-serif'>REMOTE INFRASTRUCTURE MANAGEMENT SERVICES</h1>
                 <p className='text-white text-2xl font-style: italic pl-10'>Stay Data-Driven, Scale Success.</p>
             </div>
    </div> 
    </div><br />
  <div className='px-10 py-2'>
    
    <div className='pl-0 md:pl-10'>
    <h1 className='text-center font-bold text-3xl text-sky-700'>Ensure Consistency and Quality of Service With Us</h1>
    <p className='text-lg text-center text-gray-600'>MINDCOM has capabilities in supporting Remote integrated IT Infrastructure Management and Services, giving a consolidated end-to-end view of your infrastructure and applications. <br />
     We can help monitor network infrastructure, servers, applications and other components to optimize IT performance and reduce costs.</p>
    
    </div>
  </div>

  <div >
      <div className="container2">
      <div className="card">
        
          <div className="content2">
              <h1>Disaster Recovery and Business Continuity (DRBC)</h1>
              <p>MINDCOM's Disaster Recovery (DR) and Business Continuity Plan (BCP) helps you build a resilient business environment to ensure that your data is available following any type of disaster.</p>
          </div>
      </div>
      <div className="card">
          
          <div className="content2">
              <h1>Network Operations Center (NOC)</h1>
              <p>From network troubleshooting and configuration to penetration testing and intrusion detection, our team assures the reliability and stability of your network.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Server and Storage</h1>
              <p>We offer an end-to-end capacity planning, forecasting, virtualization, and 24×7 administration of your servers. We also provide comprehensive management of your data storage operations.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Database Administaration <br /> (DBA)</h1>
              <p>From completely managing your entire database environment to staff augmentation, we provide customized service programs and support all major, open-source, and cloud database platforms.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Middleware Services</h1>
              <p>Our middleware services span the full lifecycle of your IT infrastructure – Services-Oriented Architecture (SOA), Application Infrastructure, IT Lifecycle Management and Governance, and Infrastructure Optimisation.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>End User Compute (EUC)</h1>
              <p>Deliver better application availability and accessibility, simplify hardware and software provisioning, and offer peace of mind to end users.</p>
          </div>
      </div>

  </div>
  </div>

{/* 
  <section className='p-10 text-lg text-gray-600'>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis obcaecati cupiditate qui pariatur sequi ipsa quod doloribus tenetur explicabo facilis corrupti, nihil recusandae a dolorem repellendus nesciunt vel architecto inventore quidem vero officiis harum quis dolores. Odit hic veritatis necessitatibus, corporis nemo eos! Ratione amet laborum blanditiis illo sunt, fuga ipsam culpa a vitae expedita perferendis. Esse alias blanditiis est reiciendis provident eaque non omnis debitis neque repellat saepe eligendi, nulla voluptatem vero aspernatur eum, inventore accusantium, veniam perspiciatis officiis aliquam sequi. Voluptatibus ipsa exercitationem, soluta animi velit eos, dignissimos, esse accusamus et quam quo voluptates aliquam neque! Provident impedit dignissimos ducimus adipisci ea modi, placeat, ut blanditiis ab mollitia quisquam ipsa, quo eum similique nemo facere iusto maiores eos veritatis explicabo! Corporis nesciunt doloremque itaque nulla, possimus, molestiae ullam placeat qui excepturi, iusto dolores exercitationem ab. Placeat sed et deserunt obcaecati suscipit ut amet, dolorum maxime alias recusandae dolor error? Labore corrupti illo eveniet a esse quisquam voluptate odio doloremque architecto voluptates doloribus, ipsum ipsam quod harum. Pariatur ipsam dignissimos repudiandae vero fugiat. Nam, explicabo nulla! Id quae commodi cupiditate, nesciunt laborum perspiciatis fuga dicta eius tenetur dolores. Facilis, sed ex? Iure totam nihil quaerat praesentium? Sapiente, quisquam? Repudiandae!
  </section>



    <div className="row py-10 bg-slate-200">
      <div className="col-md-4">
        <div className="card3 card3-1">
          <h3>Ionic Native</h3>
          <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-2">
          <h3>UI Components</h3>
          <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
            of mobile UI components, ready to go.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-3">
          <h3>Theming</h3>
          <p>Learn how to easily customize and modify your app’s design to fit your
            brand across all mobile platform styles.</p>
        </div>
      </div>
    </div> */}
  </div>
  )
}
