import React from 'react'
import section1 from "./Main & Reliab No. 1.jpg"
import section2 from "./Main & Reliab No. 2.jpg"
import section3 from "./Main & Reliab No. 3.jpeg"
import section4 from "./Main & Reliab No. 4.png"
import section5 from "./Main & Reliab No.5.jpg"
export default function Maintenance() {
  return (
    <div className='pt-16'>
    {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
    {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
  {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
  <div className="text-2xl">
               <h1 className='text-5xl text-white font-bold text-center'>Maintenance and Reliability Services</h1>
              
           </div>
  </div> 
  </div><br /> */}

  <div className="px-8 md:px-24">
    <div className="grid md:grid-cols-3">
          <div>
            <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
          </div>
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Maintenance that Prioritizes Reliability at its Core</h1>
          <p className="text-black text-lg pr-10">Achieve Optimal Production Efficiency with MINDCOM's Reliability-Centered Maintenance Solutions. Our Services are designed to Streamline Your Maintenance and Storeroom Processes, Ensuring You Always Have the Essential Resources to Prevent Downtime and Save Costs.</p>
          </div>
    </div>
    
    <div className="grid md:grid-cols-3">
          
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">MINDCOM’s Approach to Maintenance and Reliability Services</h1>
          <p className="text-black text-lg pr-10">MINDCOM's Maintenance and Reliability Services are tailored, data-driven, and aligned to your business goals. At MINDCOM, we believe that Maintenance and Reliability Services should be customized to fit your unique business needs. That's why our experts use data analytics and operational metrics to ensure that you have the right parts in the right quantities at the right time. Our approach is focused on:
          <br />
              <b className='text-3xl font-bold text-black'>.</b>Aligning maintenance and reliability processes with your business goals. <br />
              <b className='text-3xl font-bold text-black'>.</b> Optimizing manufacturing processes using data-driven methods and technology. <br />
              <b className='text-3xl font-bold text-black'>.</b>Standardizing inventory to eliminate duplicates and focus on the most critical items, reducing inventory carrying costs.<br />
              <b className='text-3xl font-bold text-black'>.</b> Implementing the right processes, from planning and scheduling to quality assurance and storeroom management.<br />
              <b className='text-3xl font-bold text-black'>.</b>If you're looking to improve your Maintenance and Reliability Services, contact us today to schedule an assessment. Let us show you how our tailored, data-driven approach can benefit your business.</p>
          </div>
          <div>
            <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
          </div>
    </div>  
    
    <div className="grid md:grid-cols-3">
          <div>
            <img  className="px-16  pt-10 pb-10" src={section3} alt="" />
          </div>
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Maintenance and Reliability Measures</h1>
          <p className="text-black text-lg pr-10">MINDCOM's Maintenance and Reliability Services: Improving Your Production Environment</p>
          <p className="text-black text-lg pr-10">At MINDCOM, we understand the importance of reliability and efficiency in your production environment. That's why our Maintenance and Reliability Services are designed to enhance these aspects. We work closely with your team to develop processes that:</p>

          <p className="text-black text-lg pr-10">Increase the Mean Time Between Failure (MTBF) of your assets by ensuring that the components stocked in support of an operating asset are well-maintained and have a longer operating life.</p>
          <p className="text-black text-lg pr-10">Reduce Mean Time To Repair (MTTR) by streamlining the process of getting the necessary parts to repair an asset, including finding, sourcing, and transporting them. The storeroom plays a critical role in reducing MTTR by ensuring that the right part is available at the right time and in the right quantity.</p>
         </div>
    </div>






    <div className="grid md:grid-cols-3">
         
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Asset Recovery/Inventory Optimization</h1>
          <p className="text-black text-lg pr-10">MINDCOM Asset Recovery and Inventory Optimization services can help to get the most out of your investments. Our solutions are designed to help you recover value from used and surplus assets, regardless of whether you're closing a plant, downsizing, dealing with excess or obsolete equipment, or simply need to clear out old spare parts. With the help of our advanced analytical tools, we can provide you with data-driven inventory and order controls from day one. And to ensure that your future inventories align with your manufacturing expectations, our business process includes continual reviews.</p>
         </div> <div>
            <img  className="px-16  pt-10 pb-10" src={section4} alt="" />
          </div>

    </div>
    <div className="grid md:grid-cols-3">
          <div>
            <img  className="px-16  pt-10 pb-10" src={section5} alt="" />
          </div>
          <div className="pt-10 md:col-span-2">
          <h1  className="text-red-500 text-5xl font-bold">Computerized Maintenance Management Software</h1>
          <p className="text-black text-lg pr-10">Maximize efficiency and minimize costs with Computerized Maintenance Management Software (CMMS) from MINDCOM. Our CMMS solution manages the entire lifecycle of your assets, centralizes your maintenance information, automates tasks, schedules preventive maintenance, and makes data-driven decisions to help you make better business and maintenance decisions. Additionally, it offers a range of features including asset management, work order management, inventory management, purchasing, and reporting. Say goodbye to complicated, manual maintenance processes and hello to increased efficiency and cost savings with MINDCOM's CMMS.</p>
         </div>
    </div>

 
    
    </div>

  </div>
  )
}
