import React from 'react'
import section1 from "./audit No. 1.jpeg"
import section2 from "./audit No. 2.jpeg"

export default function Audits() {
  return (
    <div className='pt-16'>
      {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
      {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
    {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white font-bold text-center'>Audits & Inspections</h1>
                
             </div>
    </div> 
    </div><br /> */}

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Maximizing Safety through Comprehensive Audits and Inspections</h1>
            <p className="text-black text-lg pr-10">At MINDCOM, we believe that a proactive approach to safety is key to preventing accidents and injuries in the workplace. That’s why we offer comprehensive safety program audits and work area inspections to identify potential hazards and ensure that your safety measures are up-to-date and effective. Our audits help evaluate the effectiveness of your safety program management, while our inspections provide a detailed record of hazards for prompt corrective action. With our expert guidance, conducting and managing these vital safety activities has never been easier.</p>
            </div>
      </div>
      {/* <section>

      
    </section> */}
    
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Audit and Inspection Benefits</h1>
            <p className="text-black text-lg pr-10">Audits and inspections are vital to creating and maintaining a safe work environment. The benefits of these assessments include:
 <br />
Enhanced worker safety <br />
Decreased instances of accidents, injuries and illnesses <br />
Lower workers’ compensation costs <br />
Reduced legal claims <br />
Increased efficiency and productivity <br />
Lower risk of non-compliance with regulations. Invest in your company’s safety by scheduling an audit or inspection today.</p>
             </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
            </div>
      </div>  <br />

   
      
      </div>

    </div>
  )
}
