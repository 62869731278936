import React from 'react'
import section1 from "./Reporting11.jpeg"
import section2 from "./Reporting 2.jpeg"

export default function Reporting() {
  return (
    <div className='pt-16'>
      {/* <div className=" flex h-52 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"> */}
      {/* <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}> */}
    {/* <div className="bgofall2 flex h-30 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white font-bold text-center'>Reporting and Analytics</h1>
                
             </div>
    </div> 
    </div><br /> */}

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={section1} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Gain Clear Visibility into MRO Spend</h1>
            <p className="text-black text-lg pr-10">With MINDCOM’s advanced analytic reporting tools, you can gain a better understanding of your MRO material spend and its impact on your production process. Our tools provide the data and insights you need to make informed decisions that can improve efficiency, reduce costs, and enhance profitability. Don’t let MRO spend remain a mystery, trust MINDCOM to provide the visibility you need for better decision-making.</p>
            </div>
      </div>
      <div className='p-5 bg-red-600'>
        <h1 className='text-white text-xl font-bold text-center p-5'>The right information is as important as the right part.</h1>
        
      </div>
      <div className="grid md:grid-cols-3">
            
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Reporting and Analytics Tools</h1>
            <p className="text-black text-lg pr-10">With MINDCOM’s advanced reporting and analytics tools, you can gain better visibility and insight into your MRO inventory and purchasing. Our tools can help you:</p>
            
            </div>
            <div>
              <img  className="px-16  pt-10 pb-10" src={section2} alt="" />
            </div>
      </div>  


    
      
      </div>

    </div>
  )
}
