import React from 'react'

export default function PrivateCloud() {
  const imageUrl = "https://www.shutterstock.com/image-photo/businessman-use-laptop-padlock-cloud-260nw-763433800.jpg"
  return (
  <div className='pt-16'>
    <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}>
    <div className="bgofall2 flex h-60 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white pl-10 font-bold pt-10 font-serif'>PRIVATE CLOUD</h1>
                 <p className='text-white text-2xl font-style: italic pl-10'>Soar To New Heights With Cloud</p>
             </div>
    </div> 
    </div><br />


  <div className='p-10 grid md:grid-cols-3'>
    <div >
    <h1 className='text-3xl fot-bold text-sky-800 text-left font-bold pt-3'>Can Your IT Infrastructure Meet Your Dynamic Business Needs?</h1>
    </div>
    <div className='col-span-2 pl-0 md:pl-10'>
    <p className='text-lg text-justify text-gray-600 pl-3'>Your IT Infrastructure has a dual role today – it has to cost-effectively serve its traditional task of automating your general-purpose applications and services and at the same time help you foster innovation and support your strategic needs as you digitally transform. <br />
     Being the backbone of your enterprise operations, your IT Infrastructure has a lot more to do now and its readiness can truly set you apart from your competitors. <br />
     At MINDCOM, we have won the trust of leading global clients to transform, manage and maintain their IT and its related networks. We have 20 years of experience in managing customers’ data centers and more than 28,000 square feet of raised floor. With MINDCOM’s Private Cloud and Infrastructure, you can optimize IT assets utilization, improve uptime and system availability, and accurately budget and control costs.</p>
    
    </div>
  </div>

  <div >
      <div className="container2">
      <div className="card">
        
          <div className="content2">
              <h1>Data Monetization</h1>
              <p>Data is the new currency. Talk to us to convert your at-rest data into valuable insights that can be used externally or internally to generate revenue.</p>
          </div>
      </div>
      <div className="card">
          
          <div className="content2">
              <h1>Data Operations</h1>
              <p>Powered by agile development, we employ proven DataOps tools and frameworks in cloud-native architectures and help your data organization achieve high levels of productivity and quality.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Data Monetization</h1>
              <p>Modernize your data environment – fast and secure. Explore our cloud-based data services that leverage MINDCOM pre-engineered platform components, AI//ML-powered accelerators, and real-world experiences of skilled data experts.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Data Monetization</h1>
              <p>Modernize your data environment – fast and secure. Explore our cloud-based data services that leverage MINDCOM pre-engineered platform components, AI//ML-powered accelerators, and real-world experiences of skilled data experts.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Data Assurance</h1>
              <p>Find the best ways to deal with bad data. We give relentless attention to cleansing, conforming, and clarifying data so that the information outputs can be trusted and leveraged confidently.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Data Privacy</h1>
              <p>We analyze your information landscape for security risks, propose mitigations, and implement controls. We also help you understand your compliance risks and tell you what to do about them.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Analytics</h1>
              <p>
              Our Business Intelligence, Data Visualization, and Analytics solutions provide you with timely, accurate, and actionable information upon which you can make informed and intelligent decisions.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>AI/ML</h1>
              <p>Enable rapid innovation like never before with our expertise in leveraging AI, Machine Learning (ML), and Data Science technologies.</p>
          </div>
      </div>

  </div>

  </div>

  {/* <section className='p-10 text-lg text-gray-600'>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis obcaecati cupiditate qui pariatur sequi ipsa quod doloribus tenetur explicabo facilis corrupti, nihil recusandae a dolorem repellendus nesciunt vel architecto inventore quidem vero officiis harum quis dolores. Odit hic veritatis necessitatibus, corporis nemo eos! Ratione amet laborum blanditiis illo sunt, fuga ipsam culpa a vitae expedita perferendis. Esse alias blanditiis est reiciendis provident eaque non omnis debitis neque repellat saepe eligendi, nulla voluptatem vero aspernatur eum, inventore accusantium, veniam perspiciatis officiis aliquam sequi. Voluptatibus ipsa exercitationem, soluta animi velit eos, dignissimos, esse accusamus et quam quo voluptates aliquam neque! Provident impedit dignissimos ducimus adipisci ea modi, placeat, ut blanditiis ab mollitia quisquam ipsa, quo eum similique nemo facere iusto maiores eos veritatis explicabo! Corporis nesciunt doloremque itaque nulla, possimus, molestiae ullam placeat qui excepturi, iusto dolores exercitationem ab. Placeat sed et deserunt obcaecati suscipit ut amet, dolorum maxime alias recusandae dolor error? Labore corrupti illo eveniet a esse quisquam voluptate odio doloremque architecto voluptates doloribus, ipsum ipsam quod harum. Pariatur ipsam dignissimos repudiandae vero fugiat. Nam, explicabo nulla! Id quae commodi cupiditate, nesciunt laborum perspiciatis fuga dicta eius tenetur dolores. Facilis, sed ex? Iure totam nihil quaerat praesentium? Sapiente, quisquam? Repudiandae!
  </section> */}



    {/* <div className="row py-10 bg-slate-200">
      <div className="col-md-4">
        <div className="card3 card3-1">
          <h3>Ionic Native</h3>
          <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
        </div>
    <div>PrivateCloud</div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-2">
          <h3>UI Components</h3>
          <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
            of mobile UI components, ready to go.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-3">
          <h3>Theming</h3>
          <p>Learn how to easily customize and modify your app’s design to fit your
            brand across all mobile platform styles.</p>
        </div>
      </div>
    </div> */}
  </div>
  )
}
