import React from 'react'
import toolrepairsection1 from "./ToolRepairsection11.jpg"
export default function Tool() {
  return (
    <div className='pt-16'>
     

    <div className="px-8 md:px-24">
      <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-10 pb-10" src={toolrepairsection1} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Tool Repair Services</h1>
            <p className="text-black text-lg pr-10">MINDCOM boasts highly skilled technicians for air/pneumatic, electric, and hydraulic tool repair, with decades of collective experience in servicing and repairing such tools. As an authorized service center for several renowned tool brands, we have a direct connection to manufacturers for parts and support. You can either drop off your tools for repair or opt for our pick-up service at your location. <br />
Moreover, we provide onsite inventory and parts access for most equipment and tool brands.</p>
            </div>
      </div>
      {/* <div className='h-60'>
        <h1 className='text-black text-2xl font-bold text-center p-20'>MINDCOM Supply serves as an authorized service center for these top brands:</h1>
        
      </div> */}
  
      
  

    
      
      </div>

    </div>
  )
}
