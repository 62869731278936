import React from 'react';
import card10fast from "./card10fast.jpeg"
import card10second from "./card10second.jpg"
import card10third from "./card10third.jpg"
import "./gridcard.css"
function CardGrid() {
  return (
<div class="container">
  <div class="card11">
    <div class="card__header">
      <img src={card10fast} alt="card__image" class="card__image" width="600"/>
    </div>
    <div class="card__body">
      <h4>Global Procurement</h4>
      <p className='text-lg'>Our digital sourcing and procurement strategies save clients overall costs without supply restrictions by:
                <br />
               <b className="text-3xl text-black font-bold">.</b>  Involving suppliers and related expenses <br />
              <b className="text-3xl text-black font-bold">.</b>   Taking use of our eProcurement Marketplace <br />
               <b className="text-3xl text-black font-bold">.</b>  Putting sustainable practises in place </p>
    </div>
   
  </div>
  <div class="card11">
    <div class="card__header">
      <img src={card10second} alt="card__image" class="card__image" width="600"/>
    </div>
    <div class="card__body">
   
      <h4>Storeroom Solutions</h4>
      <p className='text-lg'>Consistent and efficient materials management processes and practices reverberate throughout the plant. The goal is to reduce downtime, risk, and obsolete inventory through:

<br /><b className="text-3xl text-black font-bold">.</b>   Optimization of inventory
<br /><b className="text-3xl text-black font-bold">.</b>   Outstanding operational excellence
<br /><b className="text-3xl text-black font-bold">.</b>   Identification and risk reduction for critical spares</p>
    </div>
  </div>
  <div class="card11">
    <div class="card__header">
      <img src={card10third} alt="card__image" class="card__image" width="600"/>
    </div>
    <div class="card__body">

      <h4>Cost Saving Solutions</h4>
      <p className='text-lg'>  Our experts identify continuous improvement opportunities that drive down costs

<br /><b className="text-3xl text-black font-bold">. </b>Commodity Management Services
<br /><b className="text-3xl text-black font-bold">. </b>Engineering Services
<br /><b className="text-3xl text-black font-bold">. </b>CPU Management
<br /><b className="text-3xl text-black font-bold">. </b>Process improvements
<br /><b className="text-3xl text-black font-bold">. </b>Tools/Drawing management</p>
    </div>
   
  </div>
</div>

  );
}

export default CardGrid;




