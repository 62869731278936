import { Link } from "react-router-dom";
// import { navlink } from "../data/dummydata"
import Enterprices from "./Enterprise-Software.png";
import Lifecycle from "./Lifecycle.png";
import Application from "./Application.png";
import Cloud from "./Cloud.png";
import Engineering from "./Engineering.png";
import staffing from "./staffing.png";
import solutionplus from "./solutionplus.png";
import indurstrial from "./indurstrial.png";

// import { navlink } from "../data/dummydata"

import Automobile from "./Automobile.png";
import bfsi from "./bfsi.png";
import it from "./it.png";
import solution from "./solution.png";
import leadership from "./Leadership.png";
import whatwestandfor from "./what-we-stand-for.png";
import business from "./business.png";
import affiliation from "./affiliation.png";
import location from "./Location.png";
import partners from "./Partners.png";
import whoweare from "./who-we-are.png";
import Aviation from "./aviation.jpeg";
import Education from "./education.jpeg";
import Healthcair from "./healthcair.jpeg";


import logo from "../data/images/Logo.png";

// import "./Header.css"
export const Header = () => {
  // const [responsive, setResponsive] = useState(false)
  window.onscroll = function () {};
  return (
    <>
      <nav id="menu1">
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              <img className="h-10 bg-none" src={logo} alt="MINDCOM" />
            </Link>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" className="btn close-btn">
              <i className="fas fa-times"></i>
            </label>

            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
              <Link
                to="/"
                className="desktop-item  hover:text-lime-700 font-semibold pb-2"
              >
                INDUSTRIES
              </Link>
              <input type="checkbox" id="showMega2" />
              <label htmlFor="showMega2" className="mobile-item">
                INDUSTRIES
              </label>
              <div className="mega-box">
                <div className="content">
                  {/* <!-- <div className="row">
                <img src="img.jpg" alt="">
              </div> --> */}
                  <div className="row">
                    <Link className="h-12" to="/automobile">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={Automobile}
                            alt="MINDCOM"
                          />
                        </div>
                        Automobile
                      </header>
                    </Link>
                    <Link className="h-12" to="/aviation">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                        <img
                            className="h-9 w-9 bg-none float-left"
                            src={Aviation}
                            alt="MINDCOM"
                          />
                        </div>
                        Aviation
                      </header>
                    </Link>
                    <Link className="h-12" to="/it">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={it}
                            alt="MINDCOM"
                          />
                        </div>
                        IT
                      </header>
                    </Link>
                    <Link className="h-12" to="/bfsi">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={bfsi}
                            alt="MINDCOM"
                          />
                        </div>
                        BFSI
                      </header>
                    </Link>
                    <Link className="h-12" to="/education">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                        <img
                            className="h-9 w-9 bg-none float-left"
                            src={Education}
                            alt="MINDCOM"
                          />
                        </div>
                        Education
                      </header>
                    </Link>
                    <Link className="h-12" to="/healthcare">
                      {" "}
                      <header className="">
                        <div className="float-left w-12">
                        <img
                            className="h-9 w-9 bg-none float-left"
                            src={Healthcair}
                            alt="MINDCOM"
                          />
                        </div>
                        Healthcare
                      </header>
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <Link to="/" className="desktop-item">
                SOLUTIONS
              </Link>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item">
                SOLUTIONS
              </label>
              <div className="mega-box">
                <div className="content grid grid-cols-1 divide-y">
                  <div className="row md:grid-cols-1 h-28">
                    <div>
                      <header className="">
                        <div className="float-left w-12 pt-2">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={indurstrial}
                            alt="MINDCOM"
                          />
                        </div>{" "}
                        INDUSTRIAL
                      </header>
                      <ul className="mega-links pt-1">
                        <li>
                          <Link to="/vender">Vendor Managed Inventory</Link>
                        </li>
                        <li>
                          <Link to="/vending">Vending Solutions</Link>
                        </li>
                        <li>
                          <Link to="/custom">Custom E-Catalog</Link>
                        </li>
                        <li>
                          <Link to="/tool">Tool Repair</Link>
                        </li>

                        <li>
                          <Link to="/CommodityManagement">
                            Commodity Management
                          </Link>
                        </li>
                        <li>
                          <Link to="/ChemicalManagement">
                            Chemical Management
                          </Link>
                        </li>
                        <li>
                          <Link to="/InventoryManagement">
                            Inventory Management
                          </Link>
                        </li>
                        {/* <li><Link to="/StoresManagement">StoresManagement</Link></li> */}
                        <li>
                          <Link to="/AssertManagement">Asset Management</Link>
                        </li>
                        {/* <li><Link to="/VendingSolution">VendingSolution</Link></li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="row md:grid-cols-1 h-20">
                    <div>
                      <header className="">
                        <div className="float-left w-12 pt-1">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={Enterprices}
                            alt="MINDCOM"
                          />{" "}
                        </div>
                        INTEGRATED
                      </header>
                      <ul className="mega-links pt-1">
                        <li>
                          <Link to="/storeroom">Storeroom Management</Link>
                        </li>
                        <li>
                          <Link to="/maintenance">
                            Maintenance & reliability Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/procurement">Procurement services</Link>
                        </li>
                        <li>
                          <Link to="/reporting">Reporting & Analysis</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row grid md:grid-cols-1 h-16">
                    <div>
                      <header className="pt-1">
                        <div className="float-left w-12 pt-2">
                          <img
                            className="h-9 w-9 bg-none float-left"
                            src={solutionplus}
                            alt="MINDCOM"
                          />
                        </div>
                        SOLUTION PLUS
                      </header>
                      <ul className="mega-links pt-2">
                        <li>
                          <Link to="/training">Trainings</Link>
                        </li>
                        <li>
                          <Link to="/audits">Audits & Inspections</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <Link to="/Careers">CAREERS</Link>
            </li>
            <li>
              <Link to="/contact2">CONTACT</Link>
            </li>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn">
            <i className="fas fa-bars"></i>
          </label>
        </div>
      </nav>
    </>
  );
};

{
  /* <Route exact path='/custom' component={Custom} />
<Route exact path='/Tool' component={Tool} />
<Route exact path='/vender' component={Vender} />
<Route exact path='/vending' component={Vending} />
<Route exact path='/maintenance' component={Maintenance} />
<Route exact path='/procurement' component={Procurement} />
<Route exact path='/reporting' component={Reporting} />
<Route exact path='/storeroom' component={Storeroom} />
<Route exact path='/audits' component={Audits} />
<Route exact path='/training' component={Training} /> */
}
