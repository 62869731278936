import React from 'react'
import Aviationbg from './aviation.webp'
export default function Aviation() {
    const imageUrl = Aviationbg
    return (
      <div className='pt-16 px-8 md:px-24'>
    
      <div className="grid md:grid-cols-3 ">
              <div>
                <img  className="px-16  pt-14 pb-10 h-full" src={Aviationbg} alt="" />
              </div>
              <div className="pt-10 md:col-span-2">
              <h1  className="text-red-500 text-5xl font-bold">Go Beyond Manufacturing With Intelligence</h1>
              <p className="text-black text-lg pr-10">MINDCOM offers a wide variety of specialized parts and solutions for the aviation industry. Our service as a Supply Chain Partner, adds value by being flexible in our offerings, fast turnaround and reliable.
        <br />
        Embracing modern technology tears down the barriers to innovation and enables greater collaboration across the value chain. Forward-looking customer-centric systems, designed on modern architectures drive profitable and efficient business growth. While cloud-based platforms provide the flexibility to quickly adapt to rapidly changing market and consumer behavior, and deliver more brand engagement opportunities. <br />
        By having all operational information in one place, up-to-date, and accessible by all people across different departments, you can easily streamline operations, thereby eliminating paperwork and saving money.</p></div>
        </div>

   



    
  
    <div >
       <div className="container2 bg-white">
        <div className="card py-0">
          
            <div className="content2">
            <h1>Aviation Logistics Management</h1>
                <p>Aviation Logistics Management functions like material planning, procurement, loan management, Inventory receipts, stores management, Handling & Storage of Dangerous Goods & ESD items, vendor management, sales etc. are extensively covered in our Inventory management solution.</p>
                
            </div>
        </div>
        <div className="card">
            
            <div className="content2">
                <h1>Flight Operations and FDTL Software</h1>
                <p>Our system gathers all the information you require, assimilating them in one place with all the requisite cross-linking & interlinking with the engineering, maintenance & logistics management modules – thereby saving you time and effort while providing you with consistency of data.</p>
                            </div>
        </div>
        <div className="card">
            
            <div className="content2">
                
                <h1>Aircraft Maintenance Management</h1>
                <p>One of the uniqueness of our system is its capability to monitor maintenance tasks with multiple thresholds and intervals in an intelligent manner.</p>
                            </div>
        </div>
    </div>
    </div>
        <div className='px-8 md:px-72'>
          <h1 className='text-center text-4xl font-bold pt-10 text-red-600'>Our Expertise</h1>
          <p className='text-left text-lg'>Our software solution will help you manage the Logistics functions with minimum Inventory Carrying Cost and through intelligent material planning as per the realistic consumption pattern.
              </p>
              <p className='text-left text-lg'>
              MINDCOM's software system streamlines your flight operations, no matter how demanding or complex.
              </p>
              <p className='text-left text-lg'>Creation, scheduling and execution of work orders and work package to meet the above maintenance requirements and the associated task card management are also covered by the system.
              </p>

        </div>
    </div>
  )
}
