import React from 'react'
import AutomobileImg from './automobileImg.jpg'
// import './Baground/Bagroundimg.css'
import './card/card.css'
export default function Automobile() {
  return (
    <div className='pt-16 px-8 md:px-24'>
    
    <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-12 pb-10 h-full" src={AutomobileImg} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
            <h1  className="text-red-500 text-5xl font-bold">Go Beyond Manufacturing With Intelligence</h1>
            <p className="text-black text-lg pr-10">As technology continues to benefit Manufacturing, our partners leverage our extensive skill sets on manufacturing to blend IT benefits into their existing processes.The automotive industry is facing interesting and challenging times. The challenge is to become truly global. OEMs and Tier 1s will need to centralize purchase, globalize product development, blend product architectures, and manage global inventory – not to mention build and manage a global work force. To enable this, automotive OEMs and tier 1s need a domain champion with extensive experience in the automotive eco-system and with the ability to innovate faster and reduce manufacturing overheads.Our range of service offerings for the automotive industry extends from Engineering, CMS, and Enterprise IT to KPO solutions. Enterprises rely on us to become truly global. They are able to seamlessly build an integrated enterprise across geographies and economies by leveraging our in depth expertise on the automotive vertical. The technology team can benefit greatly from our skill sets.</p>
            </div>
      </div>
    <div>
  
    </div>
    
        <div className="container2 bg-white pt-5 ">
        <div className="card">
            <div className="content2">
                <h1>Supply Chain Management</h1>
                <p>Sourcing Strategies, Supplier Integration, E-Procurement, Route Planning / Logistics, Order Management, Inventory, Scheduling Agreements, Electronic Data Interchange, Supply Chain Optimization, and Network Optimization.</p>
            </div>
        </div>
        <div className="card">
            
            <div className="content2">
                <h1>Marketing and Sales</h1>
                <p>Incentive and Pricing Management, Vehicle Configuration, Dealer Locator and Dealer Buy and Sell Portal, Retail Process Integration, Customer Relationship Management (CRM), Sales Force Automation (SFA), and Available to Promise.</p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>After-Sales</h1>
                <p>Parts and Inventory Tracking, Parts and Accessories Catalogue Marketing, Servicing, Warranty Management System, Government Regulations, and Business Analytics.</p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>Scheduling and Operations Management</h1>
                <p>Integrated Maintenance, Shop Floor Automation, Quality System, Master Production Schedule, Material Requirements Planning, Bill of Materials, Product Configuration, Regulatory Compliance and Product Costing.</p>
            </div>
        </div>
        <div className="card">
           
            <div className="content2">
                <h1 className='text-3xl'>Human Resourses and Financial Management</h1>
                <p>Recruitment, Employee Master, Payroll, Chart of Accounts, Budget, Journal and Contra Entry, Accounts Receivables and Payables, Reports.</p>
            </div>
        </div>

    </div>
    

  
      

    </div>
  )
}






