import React from 'react'
import Firstbg from "./career2.jpg"
import { Link } from "react-router-dom"
export default function Careers() {
  const imageUrl = Firstbg
  return (
    <div>
       
    
    <div className="grid md:grid-cols-3">
            <div>
              <img  className="px-16  pt-12 pb-10 h-full" src={imageUrl} alt="" />
            </div>
            <div className="pt-10 md:col-span-2">
              <div> <p className='text-2xl pt-10'><b>Contact us in </b> <br /> <b className='text-red-600'> info@mindcomservices.com</b> </p></div>
             
            
            </div>
      </div>
      <div className='h-auto bg-red-600'>
    <div className='grid md:grid-cols-3 p-5 md:p-20'>
    <div className='col-span-2'>
      <h1 className='text-xl md:text-2xl text-white font-bold pl-0 md:pl-60'>Interested in our services? Contact us for more information.</h1>
      </div>
      <div className=" pl-5 md:pl-32">
      <Link  to="/contact2">
      <button class="bg-white rounded-md text-black text-xl font-bold">Contact Us</button>
      </Link>
      </div>
    </div>
    </div>
   
  
  </div>
  )
}
