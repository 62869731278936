import React from 'react'

export default function Mservises() {
  const imageUrl = "https://media.istockphoto.com/id/1251531524/photo/a-male-business-consultant-describes-a-marketing-plan-to-set-business-strategies-for-women.jpg?s=612x612&w=0&k=20&c=-NnXEMV58Y1R6zj7ya9sFqf0WuKYSaYs2paNYl30jgs="
  return (
   

  <div className='pt-16'>
    <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}>
    <div className="bgofall2 flex h-60 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white pl-10 font-bold pt-10 font-serif'>MANAGED SERVICES</h1>
                 <p className='text-white text-2xl font-style: italic pl-10'>Right Talent in the Right Place, at the Right Time</p>
             </div>
    </div> 
    </div><br />


  <div className='px-10 py-3 '>
    <div >
    <h1 className='text-3xl fot-bold text-sky-800 text-center font-bold pt-3'>Aligning Applications with Strategic Goals
Turn IT into a Strategic Partnership for Business Growth</h1>
    </div>
    <div className=' pl-0 md:pl-10'>
    <p className='text-lg text-center text-gray-600'>To compete in today’s dynamic world, companies must deploy new technologies rapidly to help grow their businesses while controlling IT costs. At the same time, they have to manage their current application portfolios and maintain essential run-the-business operations while eliminating redundancies and inefficiencies across the enterprise. Enterprises need an efficient Managed Application Services (MAS) partner to help.</p>
    
    </div>
  </div>

  <div >
      <div className="container2">
      <div className="card">
        
          <div className="content2">
              <h1>End-to-End Managed Services</h1>
              <p>Provide omni channel support, unified services delivery, end-to-end automation, DevOps processes and business-aligned KPIs.</p>
          </div>
      </div>
      <div className="card">
          
          <div className="content2">
              <h1>Application Modernization</h1>
              <p>Identify opportunities to enhance customer experience, reduce costs and speed processes through automation, new features or add-ons.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Business-Aligned IT Services</h1>
              <p>Our customer-centric model incorporates application outsourcing, rightsizing and rebadging. Services include process optimization, portfolio rationalization and partnership governance.</p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Transition Planning and Management</h1>
              <p>Perform audit of entire IT landscape, including applications, technologies and processes. Create a tailored plan aligned with your business objectives, along aligned KPIs and transition governance.</p>
          </div>
      </div>

  </div>
  </div>

  {/* <section className='p-10 text-lg text-gray-600'>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis obcaecati cupiditate qui pariatur sequi ipsa quod doloribus tenetur explicabo facilis corrupti, nihil recusandae a dolorem repellendus nesciunt vel architecto inventore quidem vero officiis harum quis dolores. Odit hic veritatis necessitatibus, corporis nemo eos! Ratione amet laborum blanditiis illo sunt, fuga ipsam culpa a vitae expedita perferendis. Esse alias blanditiis est reiciendis provident eaque non omnis debitis neque repellat saepe eligendi, nulla voluptatem vero aspernatur eum, inventore accusantium, veniam perspiciatis officiis aliquam sequi. Voluptatibus ipsa exercitationem, soluta animi velit eos, dignissimos, esse accusamus et quam quo voluptates aliquam neque! Provident impedit dignissimos ducimus adipisci ea modi, placeat, ut blanditiis ab mollitia quisquam ipsa, quo eum similique nemo facere iusto maiores eos veritatis explicabo! Corporis nesciunt doloremque itaque nulla, possimus, molestiae ullam placeat qui excepturi, iusto dolores exercitationem ab. Placeat sed et deserunt obcaecati suscipit ut amet, dolorum maxime alias recusandae dolor error? Labore corrupti illo eveniet a esse quisquam voluptate odio doloremque architecto voluptates doloribus, ipsum ipsam quod harum. Pariatur ipsam dignissimos repudiandae vero fugiat. Nam, explicabo nulla! Id quae commodi cupiditate, nesciunt laborum perspiciatis fuga dicta eius tenetur dolores. Facilis, sed ex? Iure totam nihil quaerat praesentium? Sapiente, quisquam? Repudiandae!
  </section> */}



    {/* <div className="row py-10 bg-slate-200">
      <div className="col-md-4">
        <div className="card3 card3-1">
          <h3>Ionic Native</h3>
          <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-2">
          <h3>UI Components</h3>
          <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
            of mobile UI components, ready to go.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-3">
          <h3>Theming</h3>
          <p>Learn how to easily customize and modify your app’s design to fit your
            brand across all mobile platform styles.</p>
        </div>
      </div>
    </div> */}
  </div>
  )
}
