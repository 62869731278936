import React from 'react'

export default function Microservices() {
  const imageUrl = "https://www.infinitymesh.com/media/1271/microservices-banner.jpg"
  return (
  
  <div className='pt-16'>
    <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}>
    <div className="bgofall2 flex h-60 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white pl-10 font-bold pt-10 font-serif'>MICROSERVICES AND API</h1>
                 <p className='text-white text-2xl font-style: italic pl-10'>Ensuring Digital Success with Microservices and APIs</p>
             </div>
    </div> 
    </div><br />
  

  <div className='px-10 pt-0 pb-3 '>
    <div >
    <h1 className='text-3xl text-sky-800 text-center font-bold pt-3'>Microservices and API, The Necessary Union</h1>
    </div>
    <div className=' pl-0 md:pl-10'>
    <p className='text-lg text-center text-gray-600'>API (Application Programming Interface) is the gateway that connects disparate applications. It permits applications to communicate and transfer information to one another and define how they should interact. <br />
    Microservices are pieces of software (with isolated functions) that do a single, tiny, independent part of a bigger application.Microservices have some type of API and are often connected via APIs. <br />
    Most organizations depend heavily on APIs and microservices to build and connect applications, a primary requirement for their digital transformation.</p>
    
    </div>
  </div>

  <div >
      <div className="container2">
      <div className="card">
        
          <div className="content2">
              <h1>Enabling Digital Transformation through API and Microservices</h1>
              <p>To stay ahead in the competition, you need to provide superior products and services that are faster, better, and affordable compared to you. Your ability to use smart data, agility, and capability can put you ahead of the competition.</p>
          </div>
      </div>
      <div className="card">
        
          <div className="content2">
              <h1>Successful API and Microservices Implementation</h1>
              <p>Technology-powered innovation and business strategy has become the standard model for enterprises. Correctly done business APIs, can drive your digital advancement, while microservices-based architecture will deliver zero downtime experience.

</p>
          </div>
      </div>
  </div>
  </div>
      <div className='p-5'>
        <h1 className='text-3xl font-bold text-center text-sky-700'>Microservices Architecture and APIs to the Rescue</h1>
        <p className='text-lg text-center text-gray-600'>Microservices turn the traditional approach to software delivery in a completely new way. Instead of a single massive effort to build, integrate, and test the entire software, a microservices approach allows the same functionalities to be delivered as discrete lightweight services. They interact with the business through a set of well-defined APIs.
        APIs increase the points of presence (PoP) of the products and services. As PoPs increase, it creates the need for an architecture that would scale and enable the incremental delivery of these PoPs. Microservices architectural technique enables web scale architecture and incremental delivery of capabilities.
        In either case, the microservices approach works very well because it enables smaller changes to be delivered incrementally. It thereby speeds up delivery and avoids disruptions in service. As a result, Cloud or SAAS microservice providers can help organizations launch new functionalities and features quickly and easily. As the mobile and browser digital applications have to be exceptionally dynamic and require quick changes, microservices prove to be tremendously valuable here also.</p>
      </div>



{/* 
    <div className="row py-10 bg-slate-200">
      <div className="col-md-4">
        <div className="card3 card3-1">
          <h3>Ionic Native</h3>
          <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-2">
          <h3>UI Components</h3>
          <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
            of mobile UI components, ready to go.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-3">
          <h3>Theming</h3>
          <p>Learn how to easily customize and modify your app’s design to fit your
            brand across all mobile platform styles.</p>
        </div>
      </div>
    </div> */}
  </div>
    
  )
}
