import React from 'react'
import Firstbg from "./Financebg1.jpg"
export default function Finance() {
  const imageUrl = Firstbg
  return (
  <div className='pt-16'>
    <div className=" flex h-96 bg-cover bg-no-repeat bg-center bg-slate-600 select-none"style={{backgroundImage:`url(${imageUrl})`}}>
    <div className="bgofall2 flex h-60 w-full hover:bg-core-secondary hover:bg-opacity-25">
    <div className="text-2xl">
                 <h1 className='text-5xl text-white pl-10 font-bold pt-10 font-serif'>FINANCE AND ACCOUNTING</h1>
                 <p className='text-white text-2xl font-style: italic pl-10'>Put Your Accounting Operations in the Fast Lane</p>
             </div>
    </div> 
    </div><br />
  <div>
  <div className='px-5 py-3'>
    <div >
    <h1 className='text-3xl fot-bold text-sky-800 text-center font-bold pt-3'>Optimizing Finances to Maximize Business Outcomes</h1>
    </div>
    <div>
    <p className='text-lg text-center text-gray-600'>Our finance and accounting services help you transform and optimize your processes to realize your financial transformation goals. We bring in operational efficiency through automation and process reengineering, maximizing efficiency in accounting, procurement, and sales. This has led to tangible improvements in terms of business outcomes, cost savings, cycle time reduction, and duplicate payments avoidance, leading to better cash utilization and treasury management, and thereby, an increase in the satisfaction level of vendors, employees, and customers. <br />
     As an intelligent finance and accounting solutions provider, we aspire to simplify outcomes through intelligence. We do this by combining human intelligence with the power of automation. Our long-standing partnerships with key RPA vendors have helped us with accurate and timely processing, effective tackling of peaks and valleys, efficient management of exceptions with total transparency while achieving about 50% cost savings.</p>
    
    </div>
  </div>
  </div>
  <div >
      <div className="container2">
      <div className="card">
        
          <div className="content2">
              <h1>Procure To Pay</h1>
              <p>
              . Invoice <br />
              . Vendor Helpdesk <br />
              . Exception Handling <br />
              . T&E Processing <br />
              . P-Card Administration <br />

              </p>
          </div>
      </div>
      <div className="card">
          
          <div className="content2">
              <h1>Order to Cash</h1>
              <p>. Order Management <br /> 
              . Global Trade Compliance <br />
              . Cash Application <br />
              . Collections<br />
              . Returns Management<br />
              . Customer Helpdesk<br />
              </p>
          </div>
      </div>
      <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'> Record to Report</h1>
              <p>. Fixed Asset Accounting <br />
              . Cost Accounting<br />
              . GL&Bank Reconcillations<br />
              . Period End Close<br />
              . Journal Entries<br />
              . Financial Reporting<br />
              </p>
          </div>
      </div>
      {/* <div className="card">
         
          <div className="content2">
              <h1 className='text-3xl'>Financial Planning & Analysis</h1>
              <p>. Financial Analysis<br />
              . Forecasting<br />
              . Budgeting<br />
              . SOx Compliance Support<br />
              . VAT Accounting<br />
              . VAT Accounting<br />
              </p>
          </div>
      </div> */}

  </div>
  </div>

  <div className='px-10 py-3 text-lg text-gray-600'>
  <h1 className='text-3xl fot-bold text-sky-800 text-center font-bold pt-3'>Our Finance and Accounting Services</h1>
  <p className='text-lg text-gray-600 text-center'>Over the years, many organizations across industry sectors have trusted our finance and accounting outsourcing services. We build deep, long-term business relationships with our clients by collaborating early on, maintaining confidentiality, and ensuring that our team of highly qualified financial and accounting professionals serves as their extended financial workforce. Available 24/7, 365 days a year, our clients now have access to deep skills and innovation anytime, anywhere. br Our distinctive advantage as a provider of finance and accounting outsourcing services is in our ability to help businesses accelerate their financial organization’s transformation by combining human intelligence with the power of automation. We ensure our streamlined and accurate processes deliver significant results for our clients. Further, our unified approach to managing the entire gamut of processes across the F&A function spectrum, from order to cash (accounts receivable), procure to pay (accounts payable), record to report (report generation), to other supporting functions, enables us to process accurately and on time, manage exceptions with total transparency and achieve about 50% cost savings for our clients</p>
  



          
  </div>

{/* 

    <div className="row py-10 bg-slate-200">
      <div className="col-md-4">
        <div className="card3 card3-1">
          <h3>Ionic Native</h3>
          <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-2">
          <h3>UI Components</h3>
          <p>Tabs, buttons, inputs, lists, card3s, and more! A comprehensive library
            of mobile UI components, ready to go.</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card3 card3-3">
          <h3>Theming</h3>
          <p>Learn how to easily customize and modify your app’s design to fit your
            brand across all mobile platform styles.</p>
        </div>
      </div>
    </div> */}
  </div>
  )
}
