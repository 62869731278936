import React from 'react'

import VendingSolution from "./VendingSolution.png"

export default function Stores() {
  return (
    <div className='pt-20'>

  
    <div className='px-8 md:px-64'>
    <div className='mx-auto px-2 md:px-20'>
    <h1  className="text-red-500 text-5xl font-bold">Vending Solutions</h1>
        <p className='text-lg'>MINDCOM is a Certified Crib Master distributor. Our strength lies in combining the data coming from the machine interfaced with MINDCOM “Tool Management System” that helps our customers make quality business decisions.</p>
        <p className='text-lg'>It’s the information that vending potentially provides that helps make our supply chain more efficient.</p>
       
    </div>
 
        <div>
        <p>
           <b className='text-4xl pr-2 font-bold text-red-600'>.</b> Data Driven Engineering Decisions<br />
            <b className='text-4xl pr-2 font-bold text-red-600'>.</b> Total Tool Life Management “start to finish”<br />
            <b className='text-4xl pr-2 font-bold text-red-600'>.</b>Point of Usage Data <br />
            <b className='text-4xl pr-2 font-bold text-red-600'>.</b>Data Driven Inventory Management Decisions “Inventory Control”<br />
            <b className='text-4xl pr-2 font-bold text-red-600'>.</b>CPU monitoring<br />
        </p>
        </div>
        <section className='mx-auto text-center'><img  className="h-full px-10 text-center" src={VendingSolution} alt="" /></section>
        
        
       

    

    
    </div>



    
 
  </div>
  )
}
